import { Button } from "antd";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Api } from "../../../../../api/configApi";
import { InputT } from "../../../../../plugins/Formularios/FormularioTecclas";

const FormularioModCurso = (props) => {
  const methods = useForm();

  function editarCurso(values) {
    const objData = props.data;
    delete objData.lecciones;

    Api(
      "academia",
      "editarCurso",
      { idWidget: props.infoWidget._id },
      { curso: objData, cursoInfo: values },
      "https://ed3a-181-132-3-197.ngrok.io"
    )
      .then((res) => {
        console.log(res, "editarrr");
        props.obtenerCursos()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onSubmit(values) {
    props.setVisible(false);
    methods.setValue(
      "idCurso",
      `${Math.floor(Math.random() * (9952 - 1210)) + 1210}`
    );
    editarCurso(values);
  }

  React.useEffect(() => {
    if (props.data) {
      methods.reset(props.data);
    }
  }, []);

  React.useEffect(() => {}, [props]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>idCurso</label>
            <InputT
              control={methods.control}
              name="idCurso"
              rules={{ required: false }}
            ></InputT>
          </div> */}

          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>Nombre del curso</label>
            <InputT
              control={methods.control}
              name="nombreCurso"
              placeholder="Escribe el nombre del curso"
              rules={{ required: true }}
            ></InputT>
            {methods.formState.errors.nombreCurso && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              Descripción del curso
            </label>
            <InputT
              control={methods.control}
              name="descripcionCurso"
              placeholder="Escribe la descripción del curso"
              rules={{ required: true }}
            ></InputT>
            {methods.formState.errors.descripcionCurso && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              URL de la imagen del curso
            </label>
            <InputT
              control={methods.control}
              name="urlImagen"
              placeholder="Escribe la URL de la imagen del curso"
              rules={{ required: true }}
            ></InputT>

            {methods.formState.errors.urlImagen && (
              <span style={{ color: "red" }}>Campo requerido</span>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0 20px 0",
            }}
          >
            <Button type="secondary" htmlType="htmlType">
              Guardar
            </Button>
            <Button type="secondary" danger onClick={() => {
              props.setVisible(false)
              methods.reset(props.data)
              }}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FormularioModCurso;
