import { Breadcrumb, Skeleton } from "antd";
import React from "react";
import Boton from "../../../../../components/design/elementos/boton";
import { store } from "../../../../../store";
import InvitarRegistro from "../acciones/InvitarRegistro";

function DesignWidgetAcciones(props) {
  const [widget, setWidget] = React.useState({});

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    if (widgetActual) {
      console.log(widgetActual);
      setWidget(widgetActual);
    }
  }, [props]);

  function Acciones() {
    switch (widget.accion) {
      case "invitarRegistro":
        return <InvitarRegistro widget={widget} />;
      default:
        return <Skeleton />;
    }
  }

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <Acciones />
    </div>
  );
}

export default DesignWidgetAcciones;
