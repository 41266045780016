import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { store } from "../../../../../store";

function DesignWidgetBanner(props) {
  const [widget, setWidget] = React.useState({});
  const [esCelular, setEsCelular] = React.useState(false);

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    if (widgetActual) {
      setWidget(widgetActual);
    }
    // es un celular
    if (window.innerWidth <= 768) {
      setEsCelular(true);
    } else {
      setEsCelular(false);
    }
  }, [props]);

  return (
    <div style={{ padding: 0, height: "100%" }} className="widgetShadow">
      <div style={{ height: "100%" }}>
        {widget && widget.tipoBanner === "texto" ? (
          <div
            style={{
              background: widget.fondo,
              padding: 20,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: 20 }}>{widget.titular}</h1>
            <span>{widget.contenido}</span>
          </div>
        ) : (
          <>
            <BrowserView>
              <div>
                <img
                  src={widget.imagen}
                  alt="imagen"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </BrowserView>
            <MobileView>
              <div>
                <img
                  src={widget.imagenCelulares}
                  alt="imagen"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </MobileView>
          </>
        )}
        {}
      </div>
    </div>
  );
}

export default DesignWidgetBanner;
