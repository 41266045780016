import { Button, Divider, Form, Input } from "antd";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import CreadorFormularios from "../../../../../plugins/Formularios/CreadorFormularios";

function FormConfigAcciones2_0({
  accionActual,
  guardarConfiguracionItem,
  setDrawerConfigAccion,
  disparador,
  datosAccionActual,
}) {
  const [form] = Form.useForm();
  const [cargado, setCargado] = React.useState(false);
  const methods = useForm();
  const [campos, setCampos] = React.useState([]);

  React.useEffect(() => {
    console.log(disparador, "el DISPARADOR..");
    if (datosAccionActual?.newItem && accionActual._id) {
      methods.reset(datosAccionActual.newItem);
      // form.setFieldsValue(datosAccionActual.newItem);
    }
    if (accionActual._id) {
      setCargado(true);
    }
  }, [accionActual]);

  React.useEffect(() => {
    if (accionActual?.campos) {
      setCampos([
        {
          tipoInput: "InputT",
          nombreInput: "__titulo__",
          labelInput: "Titulo de la acción",
          obligatorio: {
            valor: true,
          },
        },
        ...accionActual.campos,
      ]);
    }
  }, [accionActual]);

  const onSubmit = (values) => {
    values._accion_nombre = accionActual.titulo;
    values._accion_id = accionActual._id;
    values._accion_descripcion = accionActual.descripcion;
    values._accion_f = accionActual.f;
    guardarConfiguracionItem(values);
    setDrawerConfigAccion();
  };

  return cargado ? (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CreadorFormularios
            variables={disparador.variablesDisparador}
            listaDatos={campos}
            insertOrUpdate={false}
          />
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </form>
      </FormProvider>
    </>
  ) : null;
}

export default FormConfigAcciones2_0;
