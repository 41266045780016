import { Drawer } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import AccionesDisponibles from "./accionesDisponibles";
import FormConfigAcciones from "./FormConfigAcciones";
import lasAccionesDisponibles from "../../../../../api/estaticos/accionesDisponibles";
import FormConfigAcciones2_0 from "./FormConfigAcciones2-0";

function DrawerAccionesPersonalizadas({
  cancelar,
  accionActual,
  guardarConfiguracionItem,
  disparador,
}) {
  const [drawerConfigAccion, setDrawerConfigAccion] = React.useState(false);
  const [laAccionActual, setLaAccionActual] = React.useState({});
  React.useEffect(() => {
    if (accionActual) {
      const laF = lasAccionesDisponibles().find(
        (e) => e.f === accionActual.newItem._accion_f
      );
      setLaAccionActual(laF);
    }
  }, []);
  return (
    <>
      {!accionActual ? (
        <AccionesDisponibles
          cancelar={cancelar}
          guardarConfiguracionItem={guardarConfiguracionItem}
          disparador={disparador}
        />
      ) : laAccionActual && laAccionActual.version === 1 ? (
        <FormConfigAcciones
          guardarConfiguracionItem={guardarConfiguracionItem}
          accionActual={laAccionActual}
          datosAccionActual={accionActual}
          setDrawerConfigAccion={setDrawerConfigAccion}
          disparador={disparador}
        />
      ) : (
        <FormConfigAcciones2_0
          guardarConfiguracionItem={guardarConfiguracionItem}
          accionActual={laAccionActual}
          datosAccionActual={accionActual}
          setDrawerConfigAccion={setDrawerConfigAccion}
          disparador={disparador}
        />
      )}
    </>
  );
}

export default DrawerAccionesPersonalizadas;
