import { Switch, Button, Form, Input, Select, message, Space } from "antd";
import React from "react";
import { ConsoleView } from "react-device-detect";
import Moment from "react-moment";
import { Api } from "../../api/configApi";

import Boton from "../../components/design/elementos/boton";
import { store } from "../../store";

const { Option } = Select;

function PerfilAfiliado({ setPerfil }) {
  const [form] = Form.useForm();
  const [loscampos, setLoscampos] = React.useState([]);
  const [fechaRegistro, setFechaRegistro] = React.useState("");
  React.useEffect(() => {
    const camp = store.cuenta.formularios.find(
      (e) => e.nombre === "Nuevo registro"
    );
    setLoscampos(camp.campos);
    const camposLlenos = {};
    Api("afiliados", "perfilAfiliado", {}).then((res) => {
      console.log(res);
      res.campos.forEach((campo) => {
        camposLlenos[campo.name] = campo.valor;
      });
      setFechaRegistro(res.fechaRegistro);
      console.log(res.fechaRegistro);
      form.setFieldsValue({ campos: camposLlenos });
      setPerfil(res);
    });
  }, []);

  function guardar(values) {
    if (!values.campos.newPassword) values.campos.newPassword = "";
    Api("afiliados", "ActualizarPerfil", values).then((res) => {
      if (res.success) {
        message.success("Perfil Actualizado");
        localStorage.setItem("token", res.token);
        setPerfil(false);
      } else {
        message.error(res.msg);
      }
    });
  }
  return (
    <>
      <div style={{ background: "#000" }}>
        <Moment format="DD [de] MMMM [del] YYYY">{fechaRegistro}</Moment>
      </div>
      <Form onFinish={guardar} layout="vertical" form={form}>
        <Form.List name="campos">
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                {loscampos.map((ca, index) => {
                  return (
                    <div key={index}>
                      {(ca.type === "text" ||
                        ca.type === "number" ||
                        ca.type === "password" ||
                        ca.type === "email") && (
                        <Form.Item
                          initialValue={ca.value}
                          rules={[
                            {
                              required: ca.required,
                              message: "Campo obligatorio",
                            },
                          ]}
                          key={ca.id}
                          name={ca.name}
                          label={ca.label}
                        >
                          {(ca.type === "text" ||
                            ca.type === "number" ||
                            ca.type === "password" ||
                            ca.type === "email") && (
                            <Input type={ca.type} placeholder={ca.label} />
                          )}
                        </Form.Item>
                      )}
                      {ca.type === "textarea" && (
                        <Form.Item
                          rules={[
                            {
                              required: ca.required,
                              message: "Campo obligatorio",
                            },
                          ]}
                          key={ca.id}
                          name={ca.name}
                          label={ca.label}
                        >
                          <Input.TextArea
                            type={ca.type}
                            placeholder={ca.label}
                          />
                        </Form.Item>
                      )}

                      {ca.type === "select" && (
                        <Form.Item
                          rules={[
                            {
                              required: ca.required,
                              message: "Campo obligatorio",
                            },
                          ]}
                          key={ca.id}
                          name={ca.name}
                          label={ca.label}
                        >
                          <Select>
                            {ca.opciones.split(",").map((e) => {
                              return (
                                <Option value={e} key={e}>
                                  {e}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                      {ca.type === "checkbox" && (
                        <Form.Item
                          rules={[
                            {
                              required: ca.required,
                              message: "Campo obligatorio",
                            },
                          ]}
                          key={ca.id}
                          name={ca.name}
                          label={ca.label}
                        >
                          <Switch />
                        </Form.Item>
                      )}
                    </div>
                  );
                })}
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="Cambiar contraseña"
                rules={[{ min: 10, message: "Contraseña muy corta" }]}
                initialValue=""
              >
                <Input.Password />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Boton
          disabled={store.pensando}
          type="primary"
          htmlType="submit"
          texto="Guardar"
        />
      </Form>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setPerfil(false)}
      >
        <i
          style={{ marginTop: 16, marginRight: 10 }}
          class="fi fi-rr-angle-left"
        ></i>
        <span style={{ fontSize: 16, marginTop: 10 }}> Volver</span>
      </div>
    </>
  );
}

export default PerfilAfiliado;
