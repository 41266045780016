import { observable, action } from "mobx";

const store = observable(
  {
    version: 1.2,
    usuario: {},
    cuenta: {},
    zonaHoraria: "America/Bogota",

    licenciaActiva: false,
    afiliado: {},
    notificaciones: [],
    emails: [],
    listados: [],
    dominio: "",
    cargando: true,
    actualizarLaEstructura: false,
    margenContenido: "conMargenes",
    fondoImagen: "",
    fondoImagenCapa: "",
    kento: "",
    red: {},
    cuentaactiva: null,
    nombrecuentaactiva: "",
    mostrarmenu: false,
    pensando: false,
    logo: "",
    menuFront: [],
    menuAdmin: [],
    menuApp: [],
    fuente: "Poppins",
    colores: {
      primarios: ["#487ec5", "#2e63a7", "#134482"],
      secundarios: ["#000", "red", "blue"],
      textos: ["#fff", "#000", "blue"],
      header: ["#fff"],
      botones: ["#009CD8", "#38CE3B", "#FFC985", "#FF393A"],
      barralateral: ["#fff"],
      fondoContenido: ["#fff"],
    },
    user: null,
    grupoEtapaActual: "sinGrupo",
    etapas: [],
    listas: [],
    flujos: [],
    gruposFlujos: [],
    perfilForm: [],
    widgets: [],
    redesactivas: [
      { nombre: "Red unilevel", id: "unilevel" },
      { nombre: "Red Binaria", id: "binario" },
      { nombre: "Red matriz forzada", id: "matrizforzada" },
    ],
    losfiltros: [
      {
        nombre: "Desde el último corte",
        id: "idultimocote",
        config: {
          name: "filtronumeros",
          date: "15/06/2021",
          filtros: ["totaldebitos"],
        },
        descripcion: "Calcula el total desde el ultimo corte",
      },
    ],
    losatributos: {
      texts: [
        {
          tipo: "text",
          nombre: "Rango",
          opciones: ["Diamante", "Platinum"],
          descripcion: "Los rangos de cada afiliado",
          id: "rangos49395",
        },
        {
          tipo: "text",
          nombre: "Paquete",
          opciones: ["Starter", "Premium"],
          descripcion: "Paquete Actual",
          id: "paqueteactual49395",
        },
      ],
      numbers: [
        {
          tipo: "number",
          nombre: "Puntos",
        },
        {
          tipo: "number",
          nombre: "Comisiones",
        },
      ],
      dates: [
        {
          tipo: "date",
          nombre: "fecha de corte",
        },
      ],
      tags: [],
    },
    atributos: {
      lists: [
        {
          tipo: "list",
          nombre: "Debitos y Creditos de puntos",
          descripcion: "Lista todos los puntos sumados y restados del afiliado",
          opciones: [
            {
              tipo: "number",
              nombre: "puntos",
              id: "numeropuntosID",
            },
          ],
          id: "idBkshh",
        },
      ],
      texts: [
        {
          tipo: "text",
          nombre: "Nombre del perro",
          opciones: ["Peluza", "Chapulin", "Firulais", "trosqui", "roky"],
          descripcion: "El nombre de su perrito",
          id: "textoID",
        },
      ],
      numbers: [
        {
          tipo: "number",
          nombre: "Dias en rango 8",
          descripcion: "Dias acumulados en rango Zafiro",
          id: "numberId",
        },
      ],
      selects: [
        {
          tipo: "select",
          nombre: "Rangos",
          opciones: ["Zafiro", "Diamante", "Triple Diamante"],
          descripcion: "Lista todos los puntos sumados y restados del afiliado",
          id: "objectIDok",
        },
        {
          tipo: "select",
          nombre: "Mes actual",
          opciones: ["Enero", "Febrero", "Marzo"],
          descripcion: "Mes que deberia estar activo para recibir la comisión",
          id: "objectIDoka",
        },
      ],
      tags: [
        {
          tipo: "tag",
          nombre: "Etiquetas abiertas",
          opciones: "General para cualquier afiliado",
          id: "tagsids",
        },
      ],
    },
  },
  {},
  { deep: true }
);
const setStoreAgregarWidgetaMenu = action((index, val) => {
  store.menuFront[index].widgets = val;
});
const setStoreMenuAdmin = action((val) => {
  store.menuAdmin = val;
});
const setStoreMenuFront = action((val) => {
  store.menuFront = val;
});
const setStoreNotificaciones = action((val) => {
  store.notificaciones = val;
});

const setStoreEmails = action((val) => {
  store.emails = val;
});
const setStoreListados = action((val) => {
  store.listados = val;
});
const setStoreActualizarLaEstructura = action((val) => {
  store.actualizarLaEstructura = !store.actualizarLaEstructura;
});
const setStoreWidgets = action((val) => {
  store.widgets = val;
  // console.log("DESDE LA STORE");
  // console.log(val);
});
const setStoreFuente = action((val) => {
  store.fuente = val;
});
const setStoreActualizarColor = action((tipo, posicion, val) => {
  const nuevosColores = { ...store.colores };
  nuevosColores[tipo][posicion] = val;
  store.colores = nuevosColores;
});
const setStoreColores = action((val) => {
  store.colores = val;
});
const setStoreEtapas = action((val) => {
  store.etapas = val;
  // console.log(val);
});

const setStoreFlujos = action((val) => {
  store.flujos = val;
});
const setListas = action((val) => {
  store.listas = val;
});
const setNombrecuenta = action((val) => {
  store.nombrecuentaactiva = val;
});
const setUser = action((val) => {
  store.user = val;
});
const setMostrarmenu = action((val) => {
  store.mostrarmenu = val;
});
const setApp = action((val) => {
  store.app = val;
});
const setkento = action((token) => {
  store.kento = token;
});
const setPensando = action((val) => {
  store.pensando = val;
});
const setCuentaActiva = action((val) => {
  store.cuentaactiva = val;
});

const setStoreGrupoFlujos = action((val) => {
  store.gruposFlujos = val;
});

const updateStoreGrupoFlujos = action((index, valor) => {
  store.gruposFlujos[index] = valor;
});

const setStoreAtributos = action((val) => {
  store.losatributos = val;
});

const setStoreLogo = action((val) => {
  store.logo = val;
});

const setStoreUsuario = action((val) => {
  store.usuario = val;
});

const setStoreVersion = action((val) => {
  store.version = val;
});
const setStoreAfiliado = action((val) => {
  store.afiliado = val;
});
const setStoreCuenta = action((val) => {
  store.cuenta = val;
});
const setStoreRed = action((val) => {
  store.red = val;
});
const setStoreZonaHoraria = action((val) => {
  store.zonaHoraria = val;
});

const setStoreMenuApp = action((val) => {
  store.menuApp = val;
});

const setStoreCargando = action((val) => {
  store.cargando = val;
});

const setStoreLicenciaActiva = action((val) => {
  store.licenciaActiva = val;
});

const setStoreGrupoEtapaActual = action((val) => {
  store.grupoEtapaActual = val;
});

const setStorePerfilForm = action((val) => {
  store.perfilForm = val;
});

const setStoreMargenContenido = action((val) => {
  store.margenContenido = val;
});

const setStoreFondoImagen = action((val) => {
  store.fondoImagen = val;
});
const setStoreFondoImagenCapa = action((val) => {
  store.fondoImagenCapa = val;
});

export {
  store,
  setStoreMargenContenido,
  setStoreFondoImagenCapa,
  setStoreFondoImagen,
  setStoreRed,
  setStorePerfilForm,
  setStoreZonaHoraria,
  setStoreCuenta,
  setStoreAfiliado,
  setStoreEmails,
  setStoreVersion,
  setStoreActualizarLaEstructura,
  setkento,
  setStoreUsuario,
  setStoreLogo,
  setMostrarmenu,
  setCuentaActiva,
  setPensando,
  setApp,
  setUser,
  setNombrecuenta,
  setStoreEtapas,
  setListas,
  setStoreFlujos,
  setStoreGrupoFlujos,
  updateStoreGrupoFlujos,
  setStoreAtributos,
  setStoreActualizarColor,
  setStoreFuente,
  setStoreColores,
  setStoreWidgets,
  setStoreMenuAdmin,
  setStoreMenuFront,
  setStoreAgregarWidgetaMenu,
  setStoreNotificaciones,
  setStoreMenuApp,
  setStoreListados,
  setStoreCargando,
  setStoreLicenciaActiva,
  setStoreGrupoEtapaActual,
};
