function CondicionesDisponibles() {
  return [
    {
      _id: "fghdgffhgdh934985784357",
      version: 2,
      titulo: "Verificar si existe items en un listado",
      descripcion:
        "Verificar si existe items con condiciones especificas en un listado",
      tags: ["Listados"],
      f: "itemListExist",
      campos: [
        {
          tipoInput: "CondicionesCamposListado",
          nombreInput: "fdgfdgdfgfd",
          labelInput: "Nombre a mostrar del input",
          obligatorio: {
            campo: true,
            condicion: true,
            valorReferencia: true,
          },
        },
        {
          tipoInput: "InputNumberT",
          nombreInput: "minimo",
          labelInput: "Valor minimo",
          obligatorio: {
            valor: true,
          },
        },
        {
          tipoInput: "InputNumberT",
          nombreInput: "maximo",
          labelInput: "valor maximo",
          obligatorio: {
            valor: true,
          },
        },
      ],
    },
  ];
}

export default CondicionesDisponibles;
