import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/core/Alert";
import { message } from "antd";
import { store, setUser } from "../../store";
import { Auth, Api } from "../../api/configApi";

function CrearCuenta(props) {
  const [email, setEmail] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [telefono, setTelefono] = React.useState();
  const [password, setPassword] = React.useState("");
  const [licencia, setLicencia] = React.useState("");
  const [nombredecuenta, setNombredecuenta] = React.useState("");
  const [toast, setToast] = React.useState(false);
  const [textoError, setTextoError] = React.useState("");
  const [pensando, setPensando] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [dominioPulsof, setDominioPulsof] = React.useState(false);

  React.useEffect(() => {
    const c = window.location.hostname;
    // console.log("host: " + c);
    (c === "licencia.pulsof.com" || c === "conecta.pulsof.com") &&
      setDominioPulsof(true);
    setNombre("");
    setTelefono("");
    setPassword("");
    setEmail("");
  }, [tab]);

  async function crearcuenta() {
    setPensando(true);

    // regex para validar email
    function validarEmail(email) {
      var re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return re.test(email);
    }

    var emailbien = validarEmail(email);

    // const emailbien =
    //   /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
    //     email
    //   );
    // verificar si el email es valido

    const passbien = password.length > 6;
    const telbien = telefono > 0;
    const nombrebien = nombre.length > 6;
    const licenciabien = licencia.length > 6;

    if (emailbien && passbien && telbien && nombrebien && licenciabien) {
      Auth("register", {
        email: email,
        password: password,
        nombre: nombre,
        telefono: telefono,
        licencia: licencia,
      })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          Api("cuentas", "Insert", {
            nombre: nombredecuenta,
            admin: res.data.id,
            estado: "activo",
          }).then((resp) => {
            setUser(res.data.id);
            message.success("Cuenta Creada");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        })
        .catch((err) => {
          // console.log("Paila");
          console.log(err);
          setToast(true);
          setTextoError(err.response.data);
          setPensando(false);
        });
    } else {
      setPensando(false);
      setToast(true);
      !emailbien && setTextoError("El Email no está bien escrito");
      !passbien && setTextoError("La contraseña es muy corta");
      !telbien && setTextoError("Telefóno es incorrecto");
      !nombrebien && setTextoError("Nombre no es correcto");
      !licencia && setTextoError("Licencia no es correcta");
    }
  }

  return dominioPulsof ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        fontSize: "1.5rem",
      }}
    >
      <strong>Pulsof</strong>
    </div>
  ) : (
    <div
      style={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Backdrop
        style={{ zIndex: 2 }}
        open={pensando}
        onClick={() => {
          setPensando(false);
        }}
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast}
        onClose={() => {
          setToast(false);
        }}
        autoHideDuration={4000}
      >
        <Alert severity="error">{textoError}</Alert>
      </Snackbar>
      <div
        style={{
          marginBottom: 10,
          flexDirection: "column",
          display: "flex",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <img
          alt="logo"
          width={250}
          src="https://landingpulsof.b-cdn.net/Captura%20de%20Pantalla%202022-04-21%20a%20la(s)%2011.21.27%20a.m..png"
        />
        <br />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 300,
          marginTop: 10,
        }}
      >
        <TextField
          style={{ marginTop: 10 }}
          value={nombredecuenta}
          label="Nombre de la cuenta"
          variant="outlined"
          type="text"
          onChange={(va) => {
            setNombredecuenta(va.target.value);
          }}
        />
        <TextField
          style={{ marginTop: 10 }}
          value={nombre}
          label="Nombre del administrador"
          variant="outlined"
          type="text"
          onChange={(va) => {
            setNombre(va.target.value);
          }}
        />
        <TextField
          style={{ marginTop: 10 }}
          label="Teléfono"
          value={telefono}
          variant="outlined"
          type="number"
          onChange={(va) => {
            setTelefono(va.target.value);
          }}
        />
        <TextField
          style={{ marginTop: 10 }}
          label="Email"
          value={email}
          variant="outlined"
          type="email"
          onChange={(va) => {
            setEmail(va.target.value);
          }}
        />
        <TextField
          style={{ marginTop: 15 }}
          value={password}
          label="Contraseña"
          variant="outlined"
          onChange={(va) => setPassword(va.target.value)}
          type="password"
        />
        <TextField
          style={{ marginTop: 15 }}
          value={licencia}
          label="Licencia"
          variant="outlined"
          onChange={(va) => setLicencia(va.target.value)}
          type="text"
        />
        <Button
          onClick={() => {
            crearcuenta();
          }}
          style={{ marginTop: 15 }}
          variant="contained"
          color="inherit"
        >
          Crear Cuenta
        </Button>
        <span style={{ marginTop: 20 }}>Version: {store.version}</span>
      </div>
    </div>
  );
}

export default CrearCuenta;
