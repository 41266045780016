import React from "react";

const Modal = ({ children, color, ancho, alto, mostrar, setMostrar }) => {
  return (
    <>
      {mostrar && (
        <div
          onClick={() => setMostrar(false)}
          style={{
            background: "rgba(0, 0, 0, 0.6)",
            width: "100%",
            height: "100%",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "1999",
          }}
        ></div>
      )}

      {mostrar && (
        <div
          style={{
            backgroundColor: color ? color : "",
            width: ancho ? ancho : "300px",
            height: alto ? alto : "300px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: 2000,
            display:"flex",
            flexDirection:"column"
          }}
        >
          <div
            style={{
              textAlign: "right",
              fontWeight: "500",
              cursor: "pointer",
              color: "#fff",
              
            }}
            onClick={() => setMostrar(false)}
          >
            Cerrar
          </div>
          <div style={{ backgroundColor: "#fff", height:"100%", display:"flex"}}>{children}</div>
        </div>
      )}
    </>
  );
};

export default Modal;
