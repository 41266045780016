import React from "react";

import { store } from "../../../../../store";
import { Api } from "../../../../../api/configApi";

function DesignWidgetNumero(props) {
  const [widget, setWidget] = React.useState({});
  const [contenido, setContenido] = React.useState();
  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);
  React.useEffect(() => {
    if (widget._id) {
      Api("atributosNumero", "GetTotal", { widget: widget._id }, {}).then(
        (res) => {
          // var formatter = new Intl.NumberFormat("en-ES", {
          //   style: "currency",
          //   currency: "BTC",
          // });
          res.success ? setContenido(res.total) : setContenido(0);
        }
      );
    }
  }, [widget]);

  return (
    <div style={{padding: 20}} className="widgetShadow"> 
      <span>{widget.titulo || ""}</span>
      <div style={estilos.contenido}>
        <span>{widget.prefijo || ""}</span>
        <strong>{contenido || "0"}</strong>
        <span style={{ fontSize: 25 }}>{widget.sufijo || ""}</span>
      </div>
    </div>
  );
}
const estilos = {
  contenido: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 30,
    fontWeight: 400,
  },
};
export default DesignWidgetNumero;
