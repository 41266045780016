import { Button, Drawer, Input, Space, Spin, Tag } from "antd";

import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import CondicionesDisponibles from "../../../api/estaticos/condicionesDisponibles";
import CreadorFormularios from "../../../plugins/Formularios/CreadorFormularios";

function ListadoCondicionesPersonalizadas({
  cancelar,
  condiciones,
  condicionPersonalizadaActual,
  condicionActual,
  variables,
}) {
  const [lasCondiciones] = React.useState(CondicionesDisponibles());
  const [condicionSeleccionada, setCondicionSeleccionada] = React.useState({
    campos: [],
  });
  const [drawerConfigAccion, setDrawerConfigAccion] = React.useState(false);

  const [campos, setCampos] = React.useState([]);

  const methods = useForm();

  React.useEffect(() => {
    setCampos([
      {
        tipoInput: "InputT",
        nombreInput: "_Nombre",
        labelInput: "Nombre de la condición",
        obligatorio: {
          valor: true,
        },
      },
      ...condicionSeleccionada.campos,
    ]);
  }, [condicionSeleccionada]);

  React.useEffect(() => {
    if (condicionPersonalizadaActual?.funcion) {
      setCondicionSeleccionada(
        lasCondiciones.find(
          (condicion) => condicion.f === condicionPersonalizadaActual.funcion
        )
      );
      methods.reset(condicionPersonalizadaActual.data);
      setDrawerConfigAccion(true);
    }
  }, [condicionPersonalizadaActual]);

  function actualizar(val) {
    console.log("Condicion actual", condicionActual);
    condiciones((e) => {
      var condi = e.find((a) => a.id === condicionActual.id);
      condi.name = val._Nombre;
      condi.data = val;
      condi.funcion = condicionSeleccionada.f;
      return [...e];
    });
    cancelar(false);
    setDrawerConfigAccion(false);
  }

  const onSubmit = (data) => {
    actualizar(data);
  };

  return false ? (
    <Spin />
  ) : (
    <>
      <Drawer
        visible={drawerConfigAccion}
        title={condicionActual.name}
        onClose={() => setDrawerConfigAccion(false)}
        width={700}
        destroyOnClose
      >
        {campos.length > 0 && (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <CreadorFormularios
                listaDatos={campos}
                insertOrUpdate={false}
                variables={variables}
              />
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </form>
          </FormProvider>
        )}
      </Drawer>
      <div style={{ padding: 10 }}>
        <Input.Search
          style={{ marginBottom: 10 }}
          placeholder="Buscar acciones"
        />
        {lasCondiciones.map((e) => (
          <Space
            key={e.titulo}
            onClick={() => {
              setCondicionSeleccionada(e);
              setDrawerConfigAccion(true);
            }}
            className="hoverOpacity"
            direction="vertical"
            style={{
              width: 300,
              padding: 20,
              background: "rgb(248 248 248)",
              borderRadius: 5,
              margin: 10,
              cursor: "pointer",
            }}
          >
            <strong style={{ fontSize: 15 }}>{e.titulo}</strong>
            <span>{e.descripcion}</span>
            <div>
              {e.tags.map((e) => (
                <Tag key={e}>{e}</Tag>
              ))}
            </div>
          </Space>
        ))}
      </div>
      <Button onClick={() => cancelar(false)}>Cancelar</Button>
    </>
  );
}

export default ListadoCondicionesPersonalizadas;
