import { faClose, faCog, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Drawer,
  Dropdown,
  Menu,
  message,
  Skeleton,
  Radio,
  Modal,
  Form,
  Input,
  Space,
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import { useHistory, useParams } from "react-router";
import { Api } from "../../../api/configApi";
import Boton from "../../../components/design/elementos/boton";
import {
  setPensando,
  setStoreActualizarLaEstructura,
  setStoreEtapas,
  setStoreGrupoEtapaActual,
  store,
} from "../../../store";
import FormularioCrearDisparador from "./componentes/FormularioCrearDisparador";
import CardEtapa from "./cardEtapa";

function RenderEtapas() {
  const [etapas, setEtapas] = React.useState([]);
  const [disparador, setDisparador] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [cargando, setCargando] = React.useState(true);
  const [guardando, setGuardando] = React.useState(false);
  const [grupos, setGrupos] = React.useState([
    "Un grupo",
    "Verificar afiliados",
  ]);
  const [grupoActual, setGrupoActual] = React.useState("todos");
  const [modalCrearGrupo, setModalCrearGrupo] = React.useState(false);
  const [form] = Form.useForm();

  const params = useParams();
  const history = useHistory();

  function eliminarFlujo() {
    if (params.id === params.idgrupo)
      return message.error("No puedes eliminar el flujo base");
    if (store.etapas.length > 0)
      return message.error("Debes eliminar primero las etapas");
    setPensando(true);
    Api(
      "cuentas",
      "DeleteFlujo",
      { _id: store.cuentaactiva },
      { flujo: params.id }
    ).then((res) => {
      if (res.success) {
        setPensando(false);
        message.success("Flujo Eliminado");
        setStoreActualizarLaEstructura();
        history.push(params.idgrupo);
      } else {
        message.error("Algo no salio bien");
      }
    });
  }

  function CargarDisparador() {
    Api("disparadores", "Get", { idFlujo: params.id }).then((res) => {
      if (res) {
        setDisparador(res.datos);
      }
    });
  }
  function GetEtapas() {
    let mounted = true;
    setPensando(true);
    CargarDisparador();
    setCargando(true);
    Api("etapas", "Get", {
      cuenta: store.cuentaactiva,
      flujo: params.id,
      grupo: params.idgrupo,
    })
      .then((etapas) => {
        console.timeEnd("Etapas");
        if (mounted) {
          setStoreEtapas(etapas);
          setPensando(false);
        }
        setCargando(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => (mounted = false);
  }
  React.useEffect(() => {
    GetEtapas();
    setGrupos(
      store.flujos.find((f) => f._id === params.id)?.gruposEtapas || []
    );
  }, [params.id]);

  React.useEffect(() => {}, [store.etapas]);

  function guardarGrupo() {
    setGuardando(true);
    const values = form.getFieldsValue();
    if (
      values.nombreGrupo.length > 2 &&
      !grupos.find((e) => e.nombre === values.nombreGrupo)
    ) {
      const flujosActualizados = [];
      store.flujos.forEach((flujo) => {
        const idAleatorioString = Math.random().toString(36).substring(2, 15);
        if (flujo._id === params.id) {
          if (!flujo?.gruposEtapas) {
            flujo.gruposEtapas = [];
          }
          flujo.gruposEtapas.push({
            nombre: values.nombreGrupo,
            id: idAleatorioString,
          });
          flujosActualizados.push(flujo);
        } else {
          flujosActualizados.push(flujo);
        }
      });
      Api(
        "cuentas",
        "UpdateFlujoGrupoEtapas",
        {},
        { flujos: flujosActualizados }
      )
        .then((res) => {
          setModalCrearGrupo(false);
          form.resetFields();
          setGuardando(false);
        })
        .catch((e) => {
          setGuardando(false);
        });
    } else {
      if (!grupos.find((e) => e.nombre === values.nombreGrupo)) {
        message.error("El nombre del grupo ya existe");
      } else {
        message.error("Debes ingresar un nombre correcto para el grupo");
      }
    }
  }

  function eliminarGrupo(grupo) {
    const flujosActualizados = [];
    store.flujos.forEach((flujo) => {
      if (flujo._id === params.id) {
        if (flujo?.gruposEtapas) {
          flujo.gruposEtapas = flujo.gruposEtapas.filter((g) => g.id !== grupo);
          flujosActualizados.push(flujo);
        }
      }
    });
    Api(
      "cuentas",
      "UpdateFlujoGrupoEtapas",
      {},
      { flujos: flujosActualizados }
    ).then((res) => {
      setGrupos(
        flujosActualizados.find((f) => f._id === params.id)?.gruposEtapas || []
      );
    });
  }

  function ListarEtapas() {
    if (cargando) {
      return <span>Cargando...</span>;
    } else {
      if (!store.pensando && store.etapas.length > 0) {
        return (
          <>
            <div style={{ marginTop: -40, marginBottom: 50, maxWidth: "80%" }}>
              <Space size="large">
                <Radio.Group
                  value={grupoActual}
                  onChange={(va) => {
                    setGrupoActual(va.target.value);
                    setStoreGrupoEtapaActual(va.target.value);
                  }}
                >
                  <Radio.Button value="todos">
                    <FontAwesomeIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 15,
                        marginRight: 10,
                      }}
                      icon={faPaperclip}
                    />
                    Todos
                  </Radio.Button>
                  <Radio.Button value="sinGrupo">Sin grupo</Radio.Button>
                  {grupos.map((grupo, index) => (
                    <Radio.Button key={grupo.id} value={grupo.id}>
                      {!store.etapas.filter((e) => e.grupoEtapa === grupo.id)
                        .length > 0 && (
                        <FontAwesomeIcon
                          onClick={() => eliminarGrupo(grupo.id)}
                          icon={faClose}
                        />
                      )}

                      <span style={{ marginLeft: 10 }}>{grupo.nombre}</span>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Space>
              <span
                onClick={() => setModalCrearGrupo(true)}
                style={{ marginLeft: 15, fontSize: 11, cursor: "pointer" }}
              >
                + Crear Grupo
              </span>
            </div>

            {store.etapas
              .filter(
                (e) =>
                  e.grupoEtapa === grupoActual ||
                  grupoActual === "todos" ||
                  (grupoActual === "sinGrupo" && !e.grupoEtapa)
              )
              .sort((e, b) => e.prioridad - b.prioridad)
              .map((etapa, index) => {
                return (
                  <CardEtapa
                    key={"id" + index}
                    index={index}
                    disparador={disparador}
                    etapa={etapa}
                    cantidadEtapas={store.etapas.length}
                    GetEtapas={GetEtapas}
                    grupos={grupos}
                  />
                );
              })}
          </>
        );
      } else if (!store.pensando && etapas.length === 0) {
        return <span>No tienes etapas aún en este flujo.</span>;
      } else {
        return <></>;
      }
    }
  }

  const CargandoEtapas = observer(() => {
    return (
      <>
        {!cargando ? (
          <>
            <Drawer
              placement="right"
              width={800}
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
              title="Nuevo Disparador"
            >
              <FormularioCrearDisparador
                idFlujo={params.id}
                setVisible={setVisible}
                CargarDisparador={CargarDisparador}
                disparador={disparador}
              />
            </Drawer>

            {disparador ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: 35,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <strong
                  style={{ fontSize: 20, marginLeft: 10, marginRight: 5 }}
                >
                  Disparador:
                </strong>
                <span style={{ fontSize: 20, marginRight: 5 }}>
                  {disparador.tipo} <span style={{ opacity: 0.3 }}>|</span>
                </span>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        Editar Disparador
                      </Menu.Item>
                      <Menu.Item>Cambiar Nombre del flujo</Menu.Item>
                      {params.idgrupo !== params.id && (
                        <Menu.Item
                          style={{ color: "red" }}
                          onClick={eliminarFlujo}
                        >
                          Eliminar Flujo
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <FontAwesomeIcon
                    style={{ fontSize: 20, cursor: "pointer" }}
                    icon={faCog}
                  />
                </Dropdown>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 400,
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  margin: "auto",
                  marginTop: 20,
                }}
              >
                <strong style={{ fontSize: 30 }}>Disparador</strong>
                <p style={{ fontWeight: 200 }}>
                  Un disparador te permite procesar las etapas en momentos
                  especificos como cuando ocurre una compra o en ciertos dias a
                  la semana
                </p>
                <Boton
                  onClick={() => {
                    setVisible(true);
                  }}
                  texto="Crear Disparador"
                />
                <span
                  style={{ marginTop: 10, cursor: "pointer" }}
                  onClick={eliminarFlujo}
                >
                  Eliminar Flujo
                </span>
              </div>
            )}
            {disparador && <ListarEtapas />}
          </>
        ) : (
          <>
            <Skeleton.Button
              active={true}
              size="large"
              shape="round"
              style={{ height: 70 }}
              block={true}
            />
            <Skeleton.Button
              active={true}
              size="default"
              shape="round"
              style={{ marginTop: 10, width: 300 }}
              block={true}
            />
          </>
        )}
      </>
    );
  });

  return (
    <>
      <Modal
        visible={modalCrearGrupo}
        onOk={guardarGrupo}
        okText="Crear Grupo"
        onCancel={() => {
          setModalCrearGrupo(false);
          form.resetFields();
        }}
        confirmLoading={guardando}
        cancelText="Cancelar"
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="nombreGrupo" label="Nombre del grupo">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <CargandoEtapas />
    </>
  );
}

export default observer(RenderEtapas);
