import { Button, Drawer, Input, Space, Spin, Tag } from "antd";
import { Api } from "../../../../../api/configApi";
import React from "react";
import FormConfigAcciones from "./FormConfigAcciones";
import lasAccionesDisponibles from "../../../../../api/estaticos/accionesDisponibles";
import FormConfigAcciones2_0 from "./FormConfigAcciones2-0";

function AccionesDisponibles({
  cancelar,
  guardarConfiguracionItem,
  disparador,
}) {
  const [acciones, setAcciones] = React.useState(lasAccionesDisponibles());
  const [accionActual, setAccionActual] = React.useState({});
  const [drawerConfigAccion, setDrawerConfigAccion] = React.useState(false);
  const [cargando, setCargando] = React.useState(true);

  return false ? (
    <Spin />
  ) : (
    <>
      <Drawer
        visible={drawerConfigAccion}
        title={accionActual.titulo}
        onClose={() => setDrawerConfigAccion(false)}
        width={700}
        destroyOnClose
      >
        {accionActual.version === 1 ? (
          <FormConfigAcciones
            guardarConfiguracionItem={guardarConfiguracionItem}
            accionActual={accionActual}
            setDrawerConfigAccion={setDrawerConfigAccion}
            disparador={disparador}
          />
        ) : (
          <FormConfigAcciones2_0
            guardarConfiguracionItem={guardarConfiguracionItem}
            accionActual={accionActual}
            setDrawerConfigAccion={setDrawerConfigAccion}
            disparador={disparador}
          />
        )}
      </Drawer>
      <div style={{ padding: 10 }}>
        <Input.Search
          style={{ marginBottom: 10 }}
          placeholder="Buscar acciones"
        />
        {acciones.map((e) => (
          <Space
            key={e.titulo}
            onClick={() => {
              setAccionActual(e);
              setDrawerConfigAccion(true);
            }}
            className="hoverOpacity"
            direction="vertical"
            style={{
              width: 300,
              padding: 20,
              background: "rgb(248 248 248)",
              borderRadius: 5,
              margin: 10,
              cursor: "pointer",
            }}
          >
            <strong style={{ fontSize: 15 }}>{e.titulo}</strong>
            <span>{e.descripcion}</span>
            <div>
              {e.tags.map((e) => (
                <Tag key={e}>{e}</Tag>
              ))}
            </div>
          </Space>
        ))}
      </div>
      <Button onClick={cancelar}>Cancelar</Button>
    </>
  );
}

export default AccionesDisponibles;
