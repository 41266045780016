import React from "react";

import { CaretDownOutlined, PlayCircleOutlined } from "@ant-design/icons";

const Modulo = (props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {typeof props.mod.padre === "object" ? (
        <>
          <div
            onClick={() => {
              props.setTituloModulo(props.mod.padre.tituloLeccion);
              props.setDescripcionModulo(props.mod.padre.descripcionLeccion);
              props.setDescargables(props.mod.padre.materialDescargable);
              props.setLink(props.mod.padre.urlVideo);
              props.setEstiloMatDes(null);
              props.setEstiloInfo("2px solid #cfcfcf");
              props.setBanderaEstilo(true);
              if(props.setBanderaEstilo2){
                props.setBanderaEstilo2(false);
              }
              console.log("objeto")
            }}
            style={{
              backgroundColor: "#ededed",
              padding: "15px",
              margin: "5px 0 5px 0",
              cursor: "pointer",
            }}
          >
            <h3
              style={{
                textAlign: "left",
                margin: 0,
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              <PlayCircleOutlined style={{ marginRight: "10px" }} />
              {props.mod.padre.tituloLeccion}
            </h3>
          </div>
        </>
      ) : (
        <>
          <div>
            <div
              onClick={() => {
                setOpen(!open)
                console.log("otroo")
              }}
              style={{
                backgroundColor: "#ededed",
                padding: "15px",
                margin: "0 0 5px 0",
                cursor: "pointer",
              }}
            >
              <h3
                style={{
                  textAlign: "left",
                  margin: 0,
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {" "}
                <CaretDownOutlined style={{ marginRight: "10px" }} />
                {props.mod.padre}
              </h3>
            </div>
            {open && (
              <>
                <div style={{ backgroundColor: "#fff", padding: "10px" }}>
                  {props.mod.hijos.map((hijo, index) => {
                    return (
                      <>
                        <p
                          onClick={() => {
                            props.setTituloModulo(hijo.tituloLeccion);
                            props.setDescripcionModulo(hijo.descripcionLeccion);
                            props.setDescargables(hijo.materialDescargable);
                            props.setLink(hijo.urlVideo);
                            props.setEstiloMatDes(null);
                            props.setEstiloInfo("2px solid #cfcfcf");
                            props.setBanderaEstilo(true);
                            if(props.setBanderaEstilo2){
                              props.setBanderaEstilo2(false);
                            }
                            
                          }}
                          style={{
                            textAlign: "left",
                            cursor: "pointer",
                            margin: 0,
                            fontSize: "14px",
                          }}
                        >
                          <PlayCircleOutlined style={{ marginRight: "10px" }} />
                          {index + 1 + "." + " " + hijo.tituloLeccion}
                        </p>
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Modulo;
