import React from "react";
import { Button } from "antd";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  CheckboxT,
  DatePickerT2,
  InputNumberT,
  InputT,
  OptionT,
  RangePickerT2,
  SelectT,
} from "./FormularioTecclas";

import { store } from "../../store";

function generateRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

const DatePickerT2Creador = (props) => {
  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const [showVariables, setShowVariables] = React.useState(false);

  React.useEffect(() => {
    if(props.nombreInput.includes(".")){
      if (getValues()[props.nombreInput?.split(".")[0]][props.nombreInput?.split(".")[1]]?.[`${props.nombreInput?.split(".")[2]}_check`]) {
        setShowVariables(true);
      } else {
        setShowVariables(false);
      }
    }else {
      if (getValues()[`${props.nombreInput}_check`]) {
        setShowVariables(true);
      } else {
        setShowVariables(false);
      }
    }
  }, [props]);

  return (
    <div style={props.estiloDiv}>
      <p style={props.estiloParrafo}>{props.labelInput}</p>
      <div style={{ margin: "10px 0px 10px 0", display: "flex" }}>
        <p style={{ margin: "0 10px 0 0" }}>Elegir una variable:</p>

        <CheckboxT
          control={props.control}
          name={`${props.nombreInput}_check`}
          onChange={() => {
            setShowVariables(!showVariables);
            setValue(props.nombreInput, null);
          }}
        />
      </div>
      {!showVariables ? (
        <DatePickerT2
          control={props.control}
          name={props.nombreInput}
          rules={{ required: props.reglas.valor }}
        ></DatePickerT2>
      ) : (
        <SelectTVariables
          estiloDiv={props.estiloDiv}
          estiloParrafo={props.estiloParrafo}
          labelInput={null}
          control={props.control}
          nombreInput={props.nombreInput}
          reglas={props.reglas}
          nombreList={props.nombreList}
          variables={props.variables}
        />
      )}

      {props.array &&
        errors?.[props.nombreList]?.[props.indice]?.[
          `${props.nombreInput.split(".")[2]}`
        ]?.type === "required" && (
          <>
            <div style={{ color: "red" }}>Campo requerido</div>
          </>
        )}
      {!props.array && errors?.[`${props.nombreInput}`]?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}
    </div>
  );
};

const RangePickerT2Creador = (props) => {
  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const [showVariables, setShowVariables] = React.useState(false);

  React.useEffect(() => {
    if(props.nombreInput.includes(".")){
      if (getValues()[props.nombreInput?.split(".")[0]][props.nombreInput?.split(".")[1]]?.[`${props.nombreInput?.split(".")[2]}_check`]) {
        setShowVariables(true);
      } else {
        setShowVariables(false);
      }
    }else {
      if (getValues()[`${props.nombreInput}_check`]) {
        setShowVariables(true);
      } else {
        setShowVariables(false);
      }
    }
  }, [props]);

  return (
    <div style={props.estiloDiv}>
      <p style={props.estiloParrafo}>{props.labelInput}</p>
      <div style={{ margin: "10px 0px 10px 0", display: "flex" }}>
        <p style={{ margin: "0 10px 0 0" }}>Elegir una variable:</p>

        <CheckboxT
          control={props.control}
          name={`${props.nombreInput}_check`}
          onChange={() => {
            setShowVariables(!showVariables);
            setValue(props.nombreInput, null);
          }}
        />
      </div>
      {!showVariables ? (
        <RangePickerT2
          control={props.control}
          name={props.nombreInput}
          rules={{ required: props.reglas.valor }}
        ></RangePickerT2>
      ) : (
        <SelectTVariables
          estiloDiv={props.estiloDiv}
          estiloParrafo={props.estiloParrafo}
          labelInput={null}
          control={props.control}
          nombreInput={props.nombreInput}
          reglas={props.reglas}
          nombreList={props.nombreList}
          variables={props.variables}
        />
      )}
      {props.array &&
        errors?.[props.nombreList]?.[props.indice]?.[
          `${props.nombreInput.split(".")[2]}`
        ]?.type === "required" && (
          <>
            <div style={{ color: "red" }}>Campo requerido</div>
          </>
        )}
      {!props.array && errors?.[`${props.nombreInput}`]?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}
    </div>
  );
};

const InputNumberTCreador = (props) => {
  const [showVariables, setShowVariables] = React.useState(false);

  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  React.useEffect(() => {
    if(props.nombreInput.includes(".")){
      if (getValues()[props.nombreInput?.split(".")[0]][props.nombreInput?.split(".")[1]]?.[`${props.nombreInput?.split(".")[2]}_check`]) {
        setShowVariables(true);
      } else {
        setShowVariables(false);
      }
    }else {
      if (getValues()[`${props.nombreInput}_check`]) {
        setShowVariables(true);
      } else {
        setShowVariables(false);
      }
    }
  }, [props]);

  return (
    <div style={props.estiloDiv}>
      <p style={props.estiloParrafo}>{props.labelInput}</p>
      <div style={{ margin: "10px 0px 10px 0", display: "flex" }}>
        <p style={{ margin: "0 10px 0 0" }}>Elegir una variable:</p>

        <CheckboxT
          control={props.control}
          name={`${props.nombreInput}_check`}
          onChange={() => {
            setShowVariables(!showVariables);
            setValue(props.nombreInput, null);
            console.log(props.reglas, "REGLASSSS")
          }}
        />
      </div>
      {!showVariables ? (
        <InputNumberT
          control={props.control}
          name={props.nombreInput}
          ancho="300px"
          rules={{ required: props.reglas.valor }}
        ></InputNumberT>
      ) : (
        <SelectTVariables
          estiloDiv={props.estiloDiv}
          estiloParrafo={props.estiloParrafo}
          labelInput={null}
          control={props.control}
          nombreInput={props.nombreInput}
          reglas={props.reglas}
          nombreList={props.nombreList}
          variables={props.variables}
        />
      )}
      {props.array &&
        errors?.[props.nombreList]?.[props.indice]?.[
          `${props.nombreInput.split(".")[2]}`
        ]?.type === "required" && (
          <>
            <div style={{ color: "red" }}>Campo requerido</div>
          </>
        )}
      {!props.array && errors?.[`${props.nombreInput}`]?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}
    </div>
  );
};

const InputTCreador = (props) => {
  const [showVariables, setShowVariables] = React.useState(false);

  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  React.useEffect(() => {
    if(props.nombreInput.includes(".")){
      if (getValues()[props.nombreInput?.split(".")[0]][props.nombreInput?.split(".")[1]]?.[`${props.nombreInput?.split(".")[2]}_check`]) {
        setShowVariables(true);
      } else {
        setShowVariables(false);
      }
    }else {
      if (getValues()[`${props.nombreInput}_check`]) {
        setShowVariables(true);
      } else {
        setShowVariables(false);
      }
    }
  }, [props]);

  return (
    <div style={props.estiloDiv}>
      <p style={props.estiloParrafo}>{props.labelInput}</p>
      <div style={{ margin: "10px 0px 10px 0", display: "flex" }}>
        <p style={{ margin: "0 10px 0 0" }}>Elegir una variable:</p>

        <CheckboxT
          control={props.control}
          name={`${props.nombreInput}_check`}
          onChange={() => {
            setShowVariables(!showVariables);
            setValue(props.nombreInput, null);
          }}
        />
      </div>
      {!showVariables ? (
        <InputT
          control={props.control}
          name={props.nombreInput}
          ancho="300px"
          rules={{ required: props.reglas.valor }}
        ></InputT>
      ) : (
        <SelectTVariables
          estiloDiv={props.estiloDiv}
          estiloParrafo={props.estiloParrafo}
          labelInput={null}
          control={props.control}
          nombreInput={props.nombreInput}
          reglas={props.reglas}
          nombreList={props.nombreList}
          variables={props.variables}
        />
      )}
      {props.array &&
        errors?.[props.nombreList]?.[props.indice]?.[
          `${props.nombreInput.split(".")[2]}`
        ]?.type === "required" && (
          <>
            <div style={{ color: "red" }}>Campo requerido</div>
          </>
        )}
      {!props.array && errors?.[`${props.nombreInput}`]?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}
    </div>
  );
};

const SelectTVariables = (props) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div style={props.estiloDiv}>
      <p style={props.estiloParrafo}>{props.labelInput}</p>
      <SelectT
        control={props.control}
        name={props.nombreInput}
        ancho="300px"
        rules={{ required: props.reglas.valor }}
      >
        <OptionT value="disabled" disabled="disabled">
          Variables:
        </OptionT>
        {props.variables &&
          props.variables?.map((vari, index) => {
            return (
              <OptionT
                key={index + 342434342}
                value={vari.nombreVariable + "-" + vari.tipoVariable}
              >
                {vari.nombreVariable}
              </OptionT>
            );
          })}
      </SelectT>
      {props.array &&
        errors?.[props.nombreList]?.[props.indice]?.[
          `${props.nombreInput.split(".")[2]}`
        ]?.type === "required" && (
          <>
            <div style={{ color: "red" }}>Campo requerido</div>
          </>
        )}
      {!props.array && errors?.[`${props.nombreInput}`]?.type === "required" && (
        <>
          <div style={{ color: "red" }}>Campo requerido</div>
        </>
      )}
    </div>
  );
};

const FormFieldListT = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const refObjList = React.useRef({});
  const [objList, setObjList] = React.useState({});
  const [camposDefecto, setCamposDefecto] = React.useState([]);
  const refObj = React.useRef({});

  const { fields, append, remove, replace } = useFieldArray({
    control: control,
    name: props.nombreList,
  });

  function obtenerKeysLista() {
    props.listaInputs.forEach((elem) => {
      if (elem.tipoInput == "SelectTCamposListado") {
        refObjList.current[`${elem.nombreInput}-listado`] = null;
        refObjList.current[`${elem.nombreInput}-campo`] = null;
      } else if (elem.tipoInput == "SelectTAtributosTextoOpciones") {
        refObjList.current[`${elem.nombreInput}-atributo`] = null;
        refObjList.current[`${elem.nombreInput}-opcion`] = null;
      } else if (elem.tipoInput == "SelectTAtributosNumero") {
        refObjList.current[`${elem.nombreInput}-atributo`] = null;
      } else if (elem.tipoInput == "SelectTAtributosTexto") {
        refObjList.current[`${elem.nombreInput}-atributo`] = null;
      } else if (elem.tipoInput == "SelectTAtributosFecha") {
        refObjList.current[`${elem.nombreInput}-atributo`] = null;
      } else if (elem.tipoInput == "SelectTAtributosTodos") {
        refObjList.current[`${elem.nombreInput}-atributo`] = null;
      } else {
        refObjList.current[`${elem.nombreInput}`] = null;
      }
    });
    setObjList(refObjList.current);
  }

  function obtenerValoresDefecto() {
    props.listaInputs.forEach((elem) => {
      if (elem.tipoInput == "SelectTCamposListado") {
        refObj.current[`${elem.nombreInput}-listado`] = null;
        refObj.current[`${elem.nombreInput}-campo`] = null;
      } else if (elem.tipoInput == "SelectTAtributosTextoOpciones") {
        refObj.current[`${elem.nombreInput}-atributo`] = null;
        refObj.current[`${elem.nombreInput}-opcion`] = null;
      } else if (elem.tipoInput == "SelectTAtributosNumero") {
        refObj.current[`${elem.nombreInput}-atributo`] = null;
      } else if (elem.tipoInput == "SelectTAtributosTexto") {
        refObj.current[`${elem.nombreInput}-atributo`] = null;
      } else if (elem.tipoInput == "SelectTAtributosFecha") {
        refObj.current[`${elem.nombreInput}-atributo`] = null;
      } else if (elem.tipoInput == "SelectTAtributosTodos") {
        refObj.current[`${elem.nombreInput}-atributo`] = null;
      } else {
        refObj.current[`${elem.nombreInput}`] = null;
      }
    });
    setCamposDefecto(refObj.current);
  }

  React.useEffect(() => {
    obtenerKeysLista();
    obtenerValoresDefecto();
  }, []);

  React.useEffect(() => {
    if (props.insertOrUpdate == "InsertWidgets") {
      replace([refObj.current, ...fields]);
    }
  }, [camposDefecto, replace]);

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <p
                style={{ width: "100%", textAlign: "left", marginTop: "20px" }}
              >
                {props.listaDatos[props.indiceRecorridoDatos]?.labelList}:
              </p>
              {props.listaInputs.map((dato, indexListaInputs) => {
                return (
                  <div key={indexListaInputs}>
                    <IndexGrupoCampos
                      nombreInput={`${props.nombreList}.${index}.${dato.nombreInput}`}
                      opciones={dato.opciones}
                      tipoInput={dato.tipoInput}
                      labelInput={dato.labelInput}
                      nombreList={props.nombreList}
                      reglas={dato.obligatorio}
                      array={true}
                      indice={index}
                      variables={props.variables}
                    />
                  </div>
                );
              })}
            </div>
            {index > 0 && (
              <Button
                type="secondary"
                danger
                onClick={() => {
                  remove(index);
                }}
              >
                Eliminar{" "}
                {props.listaDatos[props.indiceRecorridoDatos]?.labelList}
              </Button>
            )}
          </div>
        );
      })}

      <div style={{ margin: "20px 0 20px 0" }}>
        <Button type="secondary" onClick={() => append(objList)}>
          Agregar {props.listaDatos[props.indiceRecorridoDatos]?.labelList}
        </Button>
      </div>
    </div>
  );
};

const CondicionesCamposListado = (props) => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
  } = useFormContext();

  const refObjeto = React.useRef({
    campo: undefined,
    condicion: undefined,
    valor: undefined,
  });

  const { fields, append, remove, replace } = useFieldArray({
    control: control,
    name: "listadoCondiciones",
  });


  React.useEffect(() => {
    if (fields.length == 0) {
      if (props.insertOrUpdate == "InsertWidgets") {
        replace([refObjeto.current, ...fields]);
      }
    }
  }, [fields]);

  return (
    <div style={{ paddingTop: "20px" }}>
      <div style={props.estiloDiv}>
        <p style={props.estiloParrafo}>Selecciona un listado:</p>
        <SelectT
          control={control}
          name="listadoSeleccionado"
          ancho="300px"
          rules={{ required: true }}
          onChange={(e) => {
            let fields = getValues();
            if (fields.listadoCondiciones) {
              delete fields.listadoCondiciones;
              fields.listadoSeleccionado = e;
              reset(fields);
            }
          }}
        >
          <OptionT
            value={`disabled-${generateRandomInt(10000, 100000)}`}
            disabled="disabled"
          >
            General:
          </OptionT>
          {store.listados?.map((listado, index) => {
            return (
              <OptionT key={index} value={listado._id}>
                {listado.nombre}
              </OptionT>
            );
          })}
          {props.variables && (
            <OptionT
              value={`disabled-${generateRandomInt(10000, 100000)}`}
              disabled="disabled"
            >
              Variables:
            </OptionT>
          )}

          {props.variables &&
            props.variables?.map((vari, index) => {
              return (
                <OptionT
                  key={index + 342342}
                  value={vari.nombreVariable + "-" + vari.tipoVariable}
                >
                  {vari.nombreVariable}
                </OptionT>
              );
            })}
        </SelectT>
        {errors?.listadoSeleccionado?.type === "required" && (
          <>
            <div style={{ color: "red" }}>Campo requerido</div>
          </>
        )}
      </div>

      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                Condicional:
              </p>
              <div style={props.estiloDiv}>
                <p style={props.estiloParrafo}>
                  Selecciona un campo del listado:
                </p>
                <SelectT
                  control={control}
                  name={`listadoCondiciones.${index}.campo`}
                  ancho="300px"
                  rules={{ required: props.reglas.campo }}
                  onChange={(e) => {
                    setValue(
                      `listadoCondiciones.${index}.valor`,
                      null
                    );
                    setValue(`listadoCondiciones.${index}.condicion`, null);
                  }}
                >
                  <OptionT
                    value={`disabled-${
                      index + generateRandomInt(10000, 100000)
                    }`}
                    disabled="disabled"
                  >
                    General:
                  </OptionT>
                  {store?.listados
                    ?.find((list) => list._id == watch("listadoSeleccionado"))
                    ?.campos?.map((listado, indice) => {
                      return (
                        <OptionT
                          key={indice + 57568546343234}
                          value={listado.NombreCampo + "-" + listado.tipoCampo}
                        >
                          {listado.NombreCampo}
                        </OptionT>
                      );
                    })}
                  {props.variables && (
                    <OptionT
                      value={`disabled-${
                        index + generateRandomInt(10000, 100000)
                      }`}
                      disabled="disabled"
                    >
                      Variables:
                    </OptionT>
                  )}

                  {props.variables &&
                    props.variables?.map((vari, index) => {
                      return (
                        <OptionT
                          key={index + 342434342}
                          value={vari.nombreVariable + "-" + vari.tipoVariable}
                        >
                          {vari.nombreVariable}
                        </OptionT>
                      );
                    })}
                </SelectT>
                {errors?.listadoCondiciones?.[index]?.campo?.type ===
                  "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
              </div>
              <div style={props.estiloDiv}>
                <p style={props.estiloParrafo}>Selecciona una condición:</p>
                <SelectT
                  control={control}
                  name={`listadoCondiciones.${index}.condicion`}
                  ancho="300px"
                  rules={{ required: props.reglas.condicion }}
                  onChange={(e) => {
                    setValue(
                      `listadoCondiciones.${index}.valor`,
                      null
                    );
                  }}
                >
                  <OptionT
                    value={`disabled-${
                      index + generateRandomInt(10000, 100000)
                    }`}
                    disabled="disabled"
                  >
                    General:
                  </OptionT>
                  {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                    "texto" &&
                    watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                      "multiple" && <OptionT value="$gt">Mayor que</OptionT>}

                  {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                    "texto" &&
                    watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                      "multiple" && (
                      <OptionT value="$gte">Mayor o igual que</OptionT>
                    )}

                  {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                    "texto" &&
                    watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                      "multiple" && <OptionT value="$lt">Menor que</OptionT>}

                  {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                    "texto" &&
                    watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                      "multiple" && (
                      <OptionT value="$lte">Menor o igual que</OptionT>
                    )}

                  <OptionT value="$eq">Igual que</OptionT>
                  {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                    "numero" &&
                    watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                      "fecha" &&
                    watch(`listadoCondiciones.${index}.campo`)
                      ?.toLowerCase()
                      ?.slice(0, 5) != "vari_" && (
                      <OptionT value="$in">Iguales a</OptionT>
                    )}
                  <OptionT value="$ne">Diferente de</OptionT>
                  {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                    "numero" &&
                    watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] !=
                      "fecha" &&
                    watch(`listadoCondiciones.${index}.campo`)
                      ?.toLowerCase()
                      ?.slice(0, 5) != "vari_" && (
                      <OptionT value="$nin">Diferentes de</OptionT>
                    )}
                  {props.variables && (
                    <OptionT
                      value={`disabled-${
                        index + generateRandomInt(10000, 100000)
                      }`}
                      disabled="disabled"
                    >
                      Variables:
                    </OptionT>
                  )}

                  {props.variables &&
                    props.variables?.map((vari, index) => {
                      return (
                        <OptionT
                          key={index + 342434342}
                          value={vari.nombreVariable + "-" + vari.tipoVariable}
                        >
                          {vari.nombreVariable}
                        </OptionT>
                      );
                    })}
                </SelectT>
                {errors?.listadoCondiciones?.[index]?.condicion?.type ===
                  "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
              </div>
              <div style={props.estiloDiv}>
                {watch(`listadoCondiciones.${index}.campo`)?.length > 0 && (
                  <p style={props.estiloParrafo}>Ingresa un valor:</p>
                )}

                {watch(`listadoCondiciones.${index}.campo`)
                  ?.slice(0, 5)
                  ?.toLowerCase() == "vari_" && (
                  <InputTCreador
                    control={control}
                    nombreInput={`listadoCondiciones.${index}.valor`}
                    reglas={props.reglas}
                    nombreList={props.nombreList}
                    variables={props.variables}
                  />
                )}

                {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] ==
                  "numero" && (
                  <InputNumberTCreador
                    control={control}
                    nombreInput={`listadoCondiciones.${index}.valor`}
                    reglas={props.reglas}
                    nombreList={props.nombreList}
                    variables={props.variables}
                  />
                )}

                {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] ==
                  "texto" &&
                  watch(`listadoCondiciones.${index}.condicion`) != "$in" &&
                  watch(`listadoCondiciones.${index}.condicion`) != "$nin" && (
                    <>
                    {console.log(props.reglas, "AXIOMA")}
                      <InputTCreador
                        control={control}
                        nombreInput={`listadoCondiciones.${index}.valor`}
                        reglas={props.reglas}
                        nombreList={props.nombreList}
                        variables={props.variables}
                      />
                    </>
                  )}

                {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] ==
                  "fecha" && (
                  <RangePickerT2Creador
                    control={control}
                    nombreInput={`listadoCondiciones.${index}.valor`}
                    reglas={props.reglas}
                    nombreList={props.nombreList}
                    variables={props.variables}
                  />
                )}

                {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] ==
                  "multiple" &&
                  (watch(`listadoCondiciones.${index}.condicion`) == "$in" ||
                    watch(`listadoCondiciones.${index}.condicion`) ==
                      "$nin") && (
                    <SelectT
                      control={control}
                      name={`listadoCondiciones.${index}.valor`}
                      ancho="300px"
                      rules={{ required: props.reglas.valor }}
                      mode="multiple"
                    >
                      <OptionT
                        value={`disabled-${
                          index + generateRandomInt(10000, 100000)
                        }`}
                        disabled="disabled"
                      >
                        General:
                      </OptionT>
                      {store?.listados
                        ?.find(
                          (list) => list._id == watch(`listadoSeleccionado`)
                        )
                        ?.campos?.find(
                          (campo) =>
                            campo.NombreCampo ==
                            watch(`listadoCondiciones.${index}.campo`)?.split(
                              "-"
                            )[0]
                        )
                        ?.opciones?.map((opc, indice) => {
                          return (
                            <OptionT
                              key={indice + 435346243562}
                              value={opc.opcion}
                            >
                              {opc.opcion}
                            </OptionT>
                          );
                        })}
                      {props.variables && (
                        <OptionT
                          value={`disabled-${
                            index + generateRandomInt(10000, 100000)
                          }`}
                          disabled="disabled"
                        >
                          Variables:
                        </OptionT>
                      )}

                      {props.variables &&
                        props.variables?.map((vari, index) => {
                          return (
                            <OptionT
                              key={index + 342434342}
                              value={
                                vari.nombreVariable + "-" + vari.tipoVariable
                              }
                            >
                              {vari.nombreVariable}
                            </OptionT>
                          );
                        })}
                    </SelectT>
                  )}

                {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] ==
                  "multiple" &&
                  watch(`listadoCondiciones.${index}.condicion`) != "$in" &&
                  watch(`listadoCondiciones.${index}.condicion`) != "$nin" && (
                    <SelectT
                      control={control}
                      name={`listadoCondiciones.${index}.valor`}
                      ancho="300px"
                      rules={{ required: props.reglas.valor }}
                    >
                      <OptionT
                        value={`disabled-${
                          index + generateRandomInt(10000, 100000)
                        }`}
                        disabled="disabled"
                      >
                        General:
                      </OptionT>
                      {store?.listados
                        ?.find(
                          (list) => list._id == watch(`listadoSeleccionado`)
                        )
                        ?.campos?.find(
                          (campo) =>
                            campo.NombreCampo ==
                            watch(`listadoCondiciones.${index}.campo`)?.split(
                              "-"
                            )[0]
                        )
                        ?.opciones?.map((opc, indice) => {
                          return (
                            <OptionT
                              key={indice + 435346243562}
                              value={opc.opcion}
                            >
                              {opc.opcion}
                            </OptionT>
                          );
                        })}
                      {props.variables && (
                        <OptionT
                          value={`disabled-${
                            index + generateRandomInt(10000, 100000)
                          }`}
                          disabled="disabled"
                        >
                          Variables:
                        </OptionT>
                      )}

                      {props.variables &&
                        props.variables?.map((vari, index) => {
                          return (
                            <OptionT
                              key={index + 342434342}
                              value={
                                vari.nombreVariable + "-" + vari.tipoVariable
                              }
                            >
                              {vari.nombreVariable}
                            </OptionT>
                          );
                        })}
                    </SelectT>
                  )}

                {watch(`listadoCondiciones.${index}.campo`)?.split("-")[1] ==
                  "texto" &&
                  (watch(`listadoCondiciones.${index}.condicion`) == "$in" ||
                    watch(`listadoCondiciones.${index}.condicion`) ==
                      "$nin") && (
                    <SelectT
                      control={control}
                      name={`listadoCondiciones.${index}.valor`}
                      ancho="300px"
                      rules={{ required: props.reglas.valor }}
                      mode="tags"
                    >
                      {[]}
                      {/* <OptionT
                        value={`disabled-${
                          index + generateRandomInt(10000, 100000)
                        }`}
                        disabled="disabled"
                      >
                        General:
                      </OptionT>
                      {store?.listados
                        ?.find(
                          (list) => list._id == watch(`listadoSeleccionado`)
                        )
                        ?.campos?.find(
                          (campo) =>
                            campo.NombreCampo ==
                            watch(`listadoCondiciones.${index}.campo`)?.split(
                              "-"
                            )[0]
                        )
                        ?.opciones?.map((opc, indice) => {
                          console.log(opc, "////////")
                          return (
                            <OptionT
                              key={indice + 435346243562}
                              value={opc.opcion}
                            >
                              {opc.opcion}
                            </OptionT>
                          );
                        })}
                        {props.variables && (
                        <OptionT
                          value={`disabled-${
                            index + generateRandomInt(10000, 100000)
                          }`}
                          disabled="disabled"
                        >
                          Variables:
                        </OptionT>
                      )}

                      {props.variables &&
                        props.variables?.map((vari, index) => {
                          return (
                            <OptionT
                              key={index + 342434342}
                              value={
                                vari.nombreVariable + "-" + vari.tipoVariable
                              }
                            >
                              {vari.nombreVariable}
                            </OptionT>
                          );
                        })} */}
                    </SelectT>
                  )}

                {errors?.listadoCondiciones?.[index]?.valor?.type ===
                  "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
              </div>
            </div>

            {index > 0 && (
              <Button
                type="secondary"
                danger
                onClick={() => {
                  remove(index);
                }}
              >
                Eliminar{" "}
                {props.listaDatos[props.indiceRecorridoDatos]?.labelList}
              </Button>
            )}
          </div>
        );
      })}

      <div style={{ margin: "20px 0 20px 0" }}>
        <Button
          type="secondary"
          onClick={() =>
            append({
              campo: undefined,
              condicion: undefined,
              valor: undefined,
            })
          }
        >
          Agregar {props.listaDatos[props.indiceRecorridoDatos]?.labelList}
        </Button>
      </div>
    </div>
  );
};

const MigracionCamposListados = (props) => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
  } = useFormContext();

  const refObjeto = React.useRef({
    campoOrigen: null,
    campoDestino: null,
  });

  const { fields, append, remove, replace } = useFieldArray({
    control: control,
    name: "migracionCamposListados",
  });

  const [banderaCompararCampos, setBanderaCompararCampos] =
    React.useState(false);

  const mapearCamposDestino = (index) => {
    return store?.listados
      ?.find((list) => list._id == watch("listadoDestino"))
      ?.campos?.map((listado, indice) => {
        if (
          watch(`migracionCamposListados.${index}.campoOrigen`)?.split(
            "-"
          )[1] == listado.tipoCampo
            ? true
            : ((watch(`migracionCamposListados.${index}.campoOrigen`)?.split(
                "-"
              )[1] == "multiple" &&
                listado.tipoCampo == "texto") ||
                (watch(`migracionCamposListados.${index}.campoOrigen`)?.split(
                  "-"
                )[1] == "texto" &&
                  listado.tipoCampo == "multiple")) &&
              true
          // &&
          // getValues().migracionCamposListados.find(
          //   (el) => el.campoDestino?.split("-")[0] == listado.NombreCampo
          // ) == undefined
        ) {
          return (
            <>
              <OptionT
                key={indice + 57568546343234}
                value={listado.NombreCampo + "-" + listado.tipoCampo}
              >
                {listado.NombreCampo}
              </OptionT>
            </>
          );
        }
      });
  };

  const compararCampos = (e) => {
    setBanderaCompararCampos(false);
    if (
      getValues().migracionCamposListados.find((el) => el.campoDestino == e) !=
      undefined
    ) {
      setBanderaCompararCampos(true);
    } else {
      setBanderaCompararCampos(false);
    }
  };

  const compararCamposEliminar = () => {
    setBanderaCompararCampos(false);
    let arr = [];
    let fields = getValues().migracionCamposListados;
    fields.forEach((el) => {
      if (!arr.includes(el.campoDestino)) {
        arr.push(el.campoDestino);
        setBanderaCompararCampos(false);
      } else {
        setBanderaCompararCampos(true);
      }
    });
  };

  React.useEffect(() => {
    if (fields.length == 0) {
      if (props.insertOrUpdate == "InsertWidgets") {
        replace([refObjeto.current, ...fields]);
      }
    }
  }, [fields]);

  return (
    <div style={{ paddingTop: "20px" }}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={props.estiloDiv}>
          <p style={props.estiloParrafo}>Selecciona un listado de origen:</p>
          <SelectT
            control={control}
            name="listadoOrigen"
            ancho="auto"
            rules={{ required: true }}
            onChange={(e) => {
              let fields = getValues();
              if (fields.migracionCamposListados) {
                delete fields.migracionCamposListados;
                fields.listadoOrigen = e;
                reset(fields);
              }
            }}
          >
            <OptionT
              value={`disabled-${generateRandomInt(10000, 100000)}`}
              disabled="disabled"
            >
              General:
            </OptionT>
            {store.listados?.map((listado, index) => {
              return (
                <OptionT key={index} value={listado._id}>
                  {listado.nombre}
                </OptionT>
              );
            })}
            {props.variables && (
              <OptionT
                value={`disabled-${generateRandomInt(10000, 100000)}`}
                disabled="disabled"
              >
                Variables:
              </OptionT>
            )}

            {props.variables &&
              props.variables?.map((vari, index) => {
                return (
                  <OptionT
                    key={index + 43624}
                    value={vari.nombreVariable + "-" + vari.tipoVariable}
                  >
                    {vari.nombreVariable}
                  </OptionT>
                );
              })}
          </SelectT>
          {errors?.listadoSeleccionado?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </div>
        <div style={props.estiloDiv}>
          <p style={props.estiloParrafo}>Selecciona un listado de destino:</p>
          <SelectT
            control={control}
            name="listadoDestino"
            ancho="auto"
            rules={{ required: true }}
            onChange={(e) => {
              let fields = getValues();
              if (fields.migracionCamposListados) {
                delete fields.migracionCamposListados;
                fields.listadoDestino = e;
                reset(fields);
              }
            }}
          >
            <OptionT
              value={`disabled-${generateRandomInt(10000, 100000)}`}
              disabled="disabled"
            >
              General:
            </OptionT>
            {store.listados?.map((listado, index) => {
              return (
                <OptionT key={index} value={listado._id}>
                  {listado.nombre}
                </OptionT>
              );
            })}
            {props.variables && (
              <OptionT
                value={`disabled-${generateRandomInt(10000, 100000)}`}
                disabled="disabled"
              >
                Variables:
              </OptionT>
            )}

            {props.variables &&
              props.variables?.map((vari, index) => {
                return (
                  <OptionT
                    key={index + 342434342}
                    value={vari.nombreVariable + "-" + vari.tipoVariable}
                  >
                    {vari.nombreVariable}
                  </OptionT>
                );
              })}
          </SelectT>
          {errors?.listadoDestino?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        </div>
      </div>

      <p
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Migración de campos:
      </p>

      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                margin: "10px 0 10px 0",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div style={props.estiloDiv}>
                  <p style={props.estiloParrafo}>
                    Selecciona un campo del listado de origen:
                  </p>
                  <SelectT
                    control={control}
                    name={`migracionCamposListados.${index}.campoOrigen`}
                    ancho="auto"
                    rules={{ required: props.reglas.campo }}
                    onChange={(e) => {
                      setValue(
                        `migracionCamposListados.${index}.campoDestino`,
                        null
                      );
                    }}
                  >
                    <OptionT
                      value={`disabled-${
                        index + generateRandomInt(10000, 100000)
                      }`}
                      disabled="disabled"
                    >
                      General:
                    </OptionT>
                    {store?.listados
                      ?.find((list) => list._id == watch("listadoOrigen"))
                      ?.campos?.map((listado, indice) => {
                        return (
                          <OptionT
                            key={indice + 57568546343234}
                            value={
                              listado.NombreCampo + "-" + listado.tipoCampo
                            }
                          >
                            {listado.NombreCampo}
                          </OptionT>
                        );
                      })}
                    {props.variables && (
                      <OptionT
                        value={`disabled-${
                          index + generateRandomInt(10000, 100000)
                        }`}
                        disabled="disabled"
                      >
                        Variables:
                      </OptionT>
                    )}

                    {props.variables &&
                      props.variables?.map((vari, index) => {
                        return (
                          <OptionT
                            key={index + 342434342}
                            value={
                              vari.nombreVariable + "-" + vari.tipoVariable
                            }
                          >
                            {vari.nombreVariable}
                          </OptionT>
                        );
                      })}
                  </SelectT>
                  {errors?.migracionCamposListados?.[index]?.campoOrigen
                    ?.type === "required" && (
                    <>
                      <div style={{ color: "red" }}>Campo requerido</div>
                    </>
                  )}
                </div>
                <div style={props.estiloDiv}>
                  <p style={props.estiloParrafo}>
                    Selecciona un campo del listado de destino:
                  </p>
                  <SelectT
                    control={control}
                    name={`migracionCamposListados.${index}.campoDestino`}
                    ancho="auto"
                    rules={{ required: props.reglas.campo }}
                    onChange={(e) => {
                      compararCampos(e);
                    }}
                  >
                    <OptionT
                      value={`disabled-${
                        index + generateRandomInt(10000, 100000)
                      }`}
                      disabled="disabled"
                    >
                      General:
                    </OptionT>
                    {mapearCamposDestino(index)}
                    {props.variables && (
                      <OptionT
                        value={`disabled-${
                          index + generateRandomInt(10000, 100000)
                        }`}
                        disabled="disabled"
                      >
                        Variables:
                      </OptionT>
                    )}

                    {props.variables &&
                      props.variables?.map((vari, index) => {
                        return (
                          <OptionT
                            key={index + 342434342}
                            value={
                              vari.nombreVariable + "-" + vari.tipoVariable
                            }
                          >
                            {vari.nombreVariable}
                          </OptionT>
                        );
                      })}
                  </SelectT>
                  {errors?.migracionCamposListados?.[index]?.campoDestino
                    ?.type === "required" && (
                    <>
                      <div style={{ color: "red" }}>Campo requerido</div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {index > 0 && (
              <Button
                type="secondary"
                danger
                onClick={() => {
                  remove(index);
                  compararCamposEliminar();
                }}
              >
                Eliminar{" "}
                {props.listaDatos[props.indiceRecorridoDatos]?.labelList}
              </Button>
            )}
          </div>
        );
      })}

      {banderaCompararCampos && (
        <div>
          <p
            style={{
              color: "red",
              textAlign: "center",
              fontSize: "42px",
              margin: 0,
            }}
          >
            ⚠
          </p>
          <p style={{ color: "red", textAlign: "center" }}>
            Existen dos o más campos de destino con el mismo valor.
          </p>
        </div>
      )}

      <div style={{ margin: "20px 0 20px 0" }}>
        <Button
          type="secondary"
          onClick={() => {
            append({
              campoOrigen: null,
              campoDestino: null,
            });
          }}
        >
          Agregar {props.listaDatos[props.indiceRecorridoDatos]?.labelList}
        </Button>
      </div>
    </div>
  );
};

const SelectTAtributos = (props) => {
  const refTotalAtributos = React.useRef([]);
  const [totalAtributos, setTotalAtributos] = React.useState([]);
  const refAtributoRetornado = React.useRef("AMIGUITO CHANNEL");
  const [atributoRetornado, setAtributoRetornado] = React.useState({});

  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  function traerOpciones(e) {
    refAtributoRetornado.current = store.losatributos.texts.find(
      (elem) => elem._id == e
    );
    setAtributoRetornado(
      store.losatributos.texts.find((elem) => elem._id == e)
    );
  }

  function tiposAtributo() {
    switch (props.tipoInput) {
      case "SelectTAtributosNumero":
        return store.losatributos?.numbers?.map((atributo, index) => {
          return (
            <OptionT key={index} value={atributo._id}>
              {atributo.nombre}
            </OptionT>
          );
        });

      case "SelectTAtributosTexto":
        return store.losatributos?.texts?.map((atributo, index) => {
          return (
            <OptionT key={index} value={atributo._id}>
              {atributo.nombre}
            </OptionT>
          );
        });
      case "SelectTAtributosTextoOpciones":
        return store.losatributos?.texts?.map((atributo, index) => {
          return (
            <OptionT key={index} value={atributo._id}>
              {atributo.nombre}
            </OptionT>
          );
        });

      case "SelectTAtributosFecha":
        return store.losatributos?.dates?.map((atributo, index) => {
          return (
            <OptionT key={index} value={atributo._id}>
              {atributo.nombre}
            </OptionT>
          );
        });

      case "SelectTAtributosTodos":
        return totalAtributos?.map((listado, index) => {
          return (
            <OptionT key={index} value={listado._id}>
              {listado.nombre}
            </OptionT>
          );
        });

      default:
        return (
          <>
            <p>por defecto</p>
          </>
        );
    }
  }

  React.useEffect(() => {
    refTotalAtributos.current = [
      ...store.losatributos.numbers,
      ...store.losatributos.texts,
      ...store.losatributos.dates,
    ];
    setTotalAtributos(refTotalAtributos.current);
    traerOpciones(watch(`${props.nombreInput}-atributo`));
  }, []);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {props.tipoInput == "SelectTAtributosTextoOpciones" && (
          <p style={props.estiloParrafo}>
            Selecciona un atributo de tipo texto:
          </p>
        )}
        <SelectT
          control={control}
          name={`${props.nombreInput}-atributo`}
          ancho="300px"
          rules={{ required: props.reglas.valorAtributo }}
          onChange={(e) => {
            if (props.tipoInput == "SelectTAtributosTextoOpciones") {
              traerOpciones(e);
              setValue(`${props.nombreInput}-opcion`, null);
            }
          }}
        >
          <OptionT
            value={`disabled-${generateRandomInt(10000, 100000)}`}
            disabled="disabled"
          >
            General:
          </OptionT>
          {tiposAtributo()}
          {props.variables && (
            <OptionT
              value={`disabled-${generateRandomInt(10000, 100000)}`}
              disabled="disabled"
            >
              Variables:
            </OptionT>
          )}

          {props.variables &&
            props.variables?.map((vari, index) => {
              return (
                <OptionT
                  key={index + 342434342}
                  value={vari.nombreVariable + "-" + vari.tipoVariable}
                >
                  {vari.nombreVariable}
                </OptionT>
              );
            })}
        </SelectT>
        {props.array &&
          errors?.[props.nombreList]?.[props.indice]?.[
            `${props.nombreInput.split(".")[2]}-atributo`
          ]?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

        {!props.array &&
          errors?.[`${props.nombreInput}-atributo`]?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
      </div>

      {props.tipoInput == "SelectTAtributosTextoOpciones" && (
        <div style={props.estiloDiv}>
          <p style={props.estiloParrafo}>Selecciona una opción del atributo:</p>
          <SelectT
            control={control}
            name={`${props.nombreInput}-opcion`}
            ancho="300px"
            rules={{ required: props.reglas.valorAtributoOpcion }}
          >
            <OptionT
              value={`disabled-${generateRandomInt(10000, 100000)}`}
              disabled="disabled"
            >
              General:
            </OptionT>
            {atributoRetornado?.opciones?.map((opcion, index) => {
              return (
                <OptionT key={index} value={opcion}>
                  {opcion}
                </OptionT>
              );
            })}
            {props.variables && (
              <OptionT
                value={`disabled-${generateRandomInt(10000, 100000)}`}
                disabled="disabled"
              >
                Variables:
              </OptionT>
            )}

            {props.variables &&
              props.variables?.map((vari, index) => {
                return (
                  <OptionT
                    key={index + 342434342}
                    value={vari.nombreVariable + "-" + vari.tipoVariable}
                  >
                    {vari.nombreVariable}
                  </OptionT>
                );
              })}
          </SelectT>
          {props.array &&
            errors?.[props.nombreList]?.[props.indice]?.[
              `${props.nombreInput.split(".")[2]}-opcion`
            ]?.type === "required" && (
              <>
                <div style={{ color: "red" }}>Campo requerido</div>
              </>
            )}

          {!props.array &&
            errors?.[`${props.nombreInput}-opcion`]?.type === "required" && (
              <>
                <div style={{ color: "red" }}>Campo requerido</div>
              </>
            )}
        </div>
      )}
    </>
  );
};

const SelectTCamposListado = (props) => {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const [listadoRetornado, setListadoRetornado] = React.useState({});

  const traerListado = (e) => {
    store.listados.find((list) => list._id == e);
    setListadoRetornado(store.listados.find((list) => list._id == e));
  };

  React.useEffect(() => {
    traerListado(watch(`${props.nombreInput}-listado`));
  }, []);

  return (
    <>
      <div style={props.estiloDiv}>
        <p style={props.estiloParrafo}>Selecciona un listado:</p>
        <SelectT
          control={control}
          name={`${props.nombreInput}-listado`}
          ancho="300px"
          rules={{ required: props.reglas.valorListado }}
          onChange={(e) => {
            setValue(`${props.nombreInput}-campo`, null);
            traerListado(e);
          }}
        >
          <OptionT
            value={`disabled-${generateRandomInt(10000, 100000)}`}
            disabled="disabled"
          >
            General:
          </OptionT>
          {store.listados?.map((listado, index) => {
            return (
              <OptionT key={index} value={listado._id}>
                {listado.nombre}
              </OptionT>
            );
          })}
          {props.variables && (
            <OptionT
              value={`disabled-${generateRandomInt(10000, 100000)}`}
              disabled="disabled"
            >
              Variables:
            </OptionT>
          )}

          {props.variables &&
            props.variables?.map((vari, index) => {
              return (
                <OptionT
                  key={index + 342434342}
                  value={vari.nombreVariable + "-" + vari.tipoVariable}
                >
                  {vari.nombreVariable}
                </OptionT>
              );
            })}
        </SelectT>
        {props.array &&
          errors?.[props.nombreList]?.[props.indice]?.[
            `${props.nombreInput.split(".")[2]}-listado`
          ]?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

        {!props.array &&
          errors?.[`${props.nombreInput}-listado`]?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
      </div>
      <div style={props.estiloDiv}>
        <p style={props.estiloParrafo}>Selecciona un campo del listado:</p>
        <SelectT
          control={control}
          name={`${props.nombreInput}-campo`}
          ancho="300px"
          rules={{ required: props.reglas.valorListadoCampo }}
        >
          <OptionT
            value={`disabled-${generateRandomInt(10000, 100000)}`}
            disabled="disabled"
          >
            General:
          </OptionT>
          {listadoRetornado?.campos?.map((listado, index) => {
            return (
              <OptionT key={index} value={listado.NombreCampo}>
                {listado.NombreCampo}
              </OptionT>
            );
          })}
          {props.variables && (
            <OptionT
              value={`disabled-${generateRandomInt(10000, 100000)}`}
              disabled="disabled"
            >
              Variables:
            </OptionT>
          )}

          {props.variables &&
            props.variables?.map((vari, index) => {
              return (
                <OptionT
                  key={index + 342434342}
                  value={vari.nombreVariable + "-" + vari.tipoVariable}
                >
                  {vari.nombreVariable}
                </OptionT>
              );
            })}
        </SelectT>
        {props.array &&
          errors?.[props.nombreList]?.[props.indice]?.[
            `${props.nombreInput.split(".")[2]}-campo`
          ]?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
        {!props.array &&
          errors?.[`${props.nombreInput}-campo`]?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}
      </div>
    </>
  );
};

const IndexGrupoCampos = (props) => {
  React.useEffect(() => {}, [props]);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const estiloParrafo = {
    textAlign: "left",
    margin: 0,
  };
  const estiloDiv = {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    margin: "10px 0",
  };

  function tiposFormato() {
    switch (props.tipoInput) {
      case "InputT":
        return (
          <>
            <InputTCreador
              estiloDiv={estiloDiv}
              estiloParrafo={estiloParrafo}
              labelInput={props.labelInput}
              control={control}
              nombreInput={props.nombreInput}
              reglas={props.reglas}
              nombreList={props.nombreList}
              variables={props.variables}
              errors={props.errors}
              array={props.array}
              indice={props.indice}
            />
          </>
        );

      case "InputNumberT":
        return (
          <>
            <InputNumberTCreador
              estiloDiv={estiloDiv}
              estiloParrafo={estiloParrafo}
              labelInput={props.labelInput}
              control={control}
              nombreInput={props.nombreInput}
              reglas={props.reglas}
              nombreList={props.nombreList}
              variables={props.variables}
              errors={props.errors}
              array={props.array}
              indice={props.indice}
            />
          </>
        );

      case "CheckboxT":
        return (
          <>
            <div style={estiloDiv}>
              <p style={estiloParrafo}>{props.labelInput}</p>
              <CheckboxT
                control={control}
                name={props.nombreInput}
                checked={false}
                rules={{ required: props.reglas.valor }}
              ></CheckboxT>
              {props.array &&
                errors?.[props.nombreList]?.[props.indice]?.[
                  `${props.nombreInput.split(".")[2]}`
                ]?.type === "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
              {!props.array &&
                errors?.[`${props.nombreInput}`]?.type === "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
            </div>
          </>
        );

      case "SelectTListados":
        return (
          <>
            <div style={estiloDiv}>
              <p style={estiloParrafo}>{props.labelInput}</p>
              <SelectT
                control={control}
                name={props.nombreInput}
                ancho="300px"
                rules={{ required: props.reglas.valor }}
              >
                <OptionT
                  value={`disabled-${generateRandomInt(10000, 100000)}`}
                  disabled="disabled"
                >
                  General:
                </OptionT>
                {store.listados?.map((listado, index) => {
                  return (
                    <OptionT key={index} value={listado._id}>
                      {listado.nombre}
                    </OptionT>
                  );
                })}
                {props.variables && (
                  <OptionT
                    value={`disabled-${generateRandomInt(10000, 100000)}`}
                    disabled="disabled"
                  >
                    Variables:
                  </OptionT>
                )}

                {props.variables &&
                  props.variables?.map((vari, index) => {
                    return (
                      <OptionT
                        key={index + 342434342}
                        value={vari.nombreVariable + "-" + vari.tipoVariable}
                      >
                        {vari.nombreVariable}
                      </OptionT>
                    );
                  })}
              </SelectT>
              {props.array &&
                errors?.[props.nombreList]?.[props.indice]?.[
                  `${props.nombreInput.split(".")[2]}`
                ]?.type === "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
              {!props.array &&
                errors?.[`${props.nombreInput}`]?.type === "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
            </div>
          </>
        );
      case "SelectTVariables":
        return (
          <SelectTVariables
            estiloDiv={estiloDiv}
            estiloParrafo={estiloParrafo}
            labelInput={props.labelInput}
            control={control}
            nombreInput={props.nombreInput}
            reglas={props.reglas}
            nombreList={props.nombreList}
            variables={props.variables}
            array={props.array}
            indice={props.indice}
          />
        );
      case "SelectTCamposListado":
        return (
          <>
            <SelectTCamposListado
              {...props}
              estiloDiv={estiloDiv}
              estiloParrafo={estiloParrafo}
              variables={props.variables}
            ></SelectTCamposListado>
          </>
        );

      case "SelectTAtributosTodos":
        return (
          <>
            <div style={estiloDiv}>
              <p style={estiloParrafo}>Selecciona un atributo:</p>
              <SelectTAtributos {...props}></SelectTAtributos>
            </div>
          </>
        );
      case "SelectTAtributosNumero":
        return (
          <>
            <div style={estiloDiv}>
              <p style={estiloParrafo}>Selecciona un atributo tipo número:</p>
              <SelectTAtributos {...props}></SelectTAtributos>
            </div>
          </>
        );
      case "SelectTAtributosTexto":
        return (
          <>
            <div style={estiloDiv}>
              <p style={estiloParrafo}>Selecciona un atributo tipo texto:</p>
              <SelectTAtributos {...props}></SelectTAtributos>
            </div>
          </>
        );
      case "SelectTAtributosTextoOpciones":
        return (
          <>
            <SelectTAtributos
              {...props}
              estiloDiv={estiloDiv}
              estiloParrafo={estiloParrafo}
            ></SelectTAtributos>
          </>
        );
      case "SelectTAtributosFecha":
        return (
          <>
            <div style={estiloDiv}>
              <p style={estiloParrafo}>Selecciona un atributo tipo fecha:</p>
              <SelectTAtributos {...props}></SelectTAtributos>
            </div>
          </>
        );
      case "SelectT":
        return (
          <>
            <div style={estiloDiv}>
              <p style={estiloParrafo}>{props.labelInput}</p>
              <SelectT
                control={control}
                name={props.nombreInput}
                ancho="300px"
                rules={{ required: props.reglas.valor }}
              >
                <OptionT
                  value={`disabled-${generateRandomInt(10000, 100000)}`}
                  disabled="disabled"
                >
                  General:
                </OptionT>
                {props.opciones?.map((opcion, index) => {
                  return (
                    <OptionT key={index} value={opcion.value}>
                      {opcion.name}
                    </OptionT>
                  );
                })}
                {props.variables && (
                  <OptionT
                    value={`disabled-${generateRandomInt(10000, 100000)}`}
                    disabled="disabled"
                  >
                    Variables:
                  </OptionT>
                )}

                {props.variables &&
                  props.variables?.map((vari, index) => {
                    return (
                      <OptionT
                        key={index + 342542}
                        value={vari.nombreVariable + "-" + vari.tipoVariable}
                      >
                        {vari.nombreVariable}
                      </OptionT>
                    );
                  })}
              </SelectT>
              {props.array &&
                errors?.[props.nombreList]?.[props.indice]?.[
                  `${props.nombreInput.split(".")[2]}`
                ]?.type === "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
              {!props.array &&
                errors?.[`${props.nombreInput}`]?.type === "required" && (
                  <>
                    <div style={{ color: "red" }}>Campo requerido</div>
                  </>
                )}
            </div>
          </>
        );

      case "RangePickerT2":
        return (
          <>
            <RangePickerT2Creador
              estiloDiv={estiloDiv}
              estiloParrafo={estiloParrafo}
              labelInput={props.labelInput}
              control={control}
              nombreInput={props.nombreInput}
              reglas={props.reglas}
              nombreList={props.nombreList}
              variables={props.variables}
              array={props.array}
              indice={props.indice}
              errors={props.errors}
            />
          </>
        );

      case "DatePickerT2":
        return (
          <>
            <DatePickerT2Creador
              estiloDiv={estiloDiv}
              estiloParrafo={estiloParrafo}
              labelInput={props.labelInput}
              control={control}
              nombreInput={props.nombreInput}
              reglas={props.reglas}
              nombreList={props.nombreList}
              variables={props.variables}
              array={props.array}
              indice={props.indice}
              errors={props.errors}
            />
          </>
        );
      case "FormArrayList":
        return (
          <>
            <FormFieldListT
              insertOrUpdate={props.insertOrUpdate}
              indiceRecorridoDatos={props.indiceRecorridoDatos}
              listaInputs={props.listaInputs}
              nombreList={props.nombreList}
              listaDatos={props.listaDatos}
              variables={props.variables}
            ></FormFieldListT>
          </>
        );

      case "CondicionesCamposListado":
        return (
          <>
            <CondicionesCamposListado
              {...props}
              estiloDiv={estiloDiv}
              estiloParrafo={estiloParrafo}
              insertOrUpdate={props.insertOrUpdate}
              reglas={props.reglas}
              variables={props.variables}
              setValoresDefecto={props.setValoresDefecto}
            ></CondicionesCamposListado>
          </>
        );

      case "MigracionCamposListados":
        return (
          <>
            <MigracionCamposListados
              {...props}
              estiloDiv={estiloDiv}
              estiloParrafo={estiloParrafo}
              insertOrUpdate={props.insertOrUpdate}
              reglas={props.reglas}
              variables={props.variables}
            ></MigracionCamposListados>
          </>
        );

      default:
        return (
          <>
            <p>por defecto</p>
          </>
        );
    }
  }

  return <>{tiposFormato()}</>;
};

function CreadorFormularios(props) {
  return (
    <div>
      {props.listaDatos &&
        props.listaDatos.map((dato, index) => {
          return (
            <div key={index}>
              <IndexGrupoCampos
                insertOrUpdate={props.insertOrUpdate}
                indiceRecorridoDatos={index}
                listaDatos={props.listaDatos}
                nombreInput={dato.nombreInput}
                opciones={dato.opciones}
                tipoInput={dato.tipoInput}
                labelInput={dato.labelInput}
                listaInputs={dato.listaInputs}
                nombreList={dato.nombreList}
                reglas={dato.obligatorio}
                variables={props.variables}
                setValoresDefecto={props.setValoresDefecto}
              ></IndexGrupoCampos>
            </div>
          );
        })}
    </div>
  );
}

export default CreadorFormularios;
