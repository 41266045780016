import React from "react";
import {
  InputNumber,
  Steps,
  Button,
  message,
  Select,
  Skeleton,
  Form,
} from "antd";
import Countdown from "react-countdown";
import { Api } from "../../../../../../api/configApi";
import { set } from "mobx";

const { Step } = Steps;

const steps = [
  {
    title: "Monto y moneda",
    content: "First-content",
  },
  {
    title: "Procesar pago",
    content: "Second-content",
  },
];

const CoinPaymentsModal = (props) => {
  const refInputValorPagar = React.useRef();
  const [inputValorPagar, setInputValorPagar] = React.useState(0);
  const [widget, SetWidget] = React.useState({});
  const [current, setCurrent] = React.useState(0);
  const [montoCripto, setMontoCripto] = React.useState(0);
  const [monedaCripto, setMonedaCripto] = React.useState("");
  const refMonedaPagar = React.useRef();
  const [monedaPagar, setMonedaPagar] = React.useState();
  const refDireccionQr = React.useRef();
  const [direccionQr, setDireccionQr] = React.useState();
  const refIdTransaccion = React.useRef();
  const refEstado = React.useRef();
  const [estado, setEstado] = React.useState();
  const refBanderaCuentaRegresiva = React.useRef();
  const [banderaCuentaRegresiva, setBanderaCuentaRegresiva] = React.useState();
  const refTiempoRestante = React.useRef();
  const refRuta = React.useRef(process.env.REACT_APP_RUTA);
  const refMontoMinimo = React.useRef();
  const refMontoMaximo = React.useRef();
  const timeCu = React.useRef();
  const refListaOpcionesMonto = React.useRef([]);
  const [listaOpcionesMonto, setListaOpcionesMonto] = React.useState([]);

  const refDatosEnviarPasarela = React.useRef({
    currency1: "",
    currency2: "",
    amount: "",
    idListado: "",
  });

  const enviarDatosPeticion = () => {
    refDatosEnviarPasarela.current.currency1 =
      props.props2.widget.monedaEscogidaMostrar.substring(
        0,
        props.props2.widget.monedaEscogidaMostrar.indexOf("-")
      );
    refDatosEnviarPasarela.current.currency2 = refMonedaPagar.current;
    refDatosEnviarPasarela.current.idListado =
      props.props2.widget.listaListados;
    if (props.props2.widget.montoPagar) {
      refDatosEnviarPasarela.current.amount = props.props2.widget.montoPagar;
    } else {
      refDatosEnviarPasarela.current.amount = refInputValorPagar.current;
    }

    Api(
      "pasarelas",
      props.props2.widget.listaIntegraciones,
      { type: "createTransaction" },
      {
        currency1: refDatosEnviarPasarela.current.currency1,
        currency2: refDatosEnviarPasarela.current.currency2,
        amount: refDatosEnviarPasarela.current.amount,
        idListado: refDatosEnviarPasarela.current.idListado,
        idWidget: props.props2.widget._id,
      },
      refRuta.current
    )
      .then((res) => {
        refIdTransaccion.current = res.txnid;
        refDireccionQr.current = res;
        setDireccionQr(refDireccionQr.current);
        enviarIdTransaccion(refIdTransaccion.current);
        setMontoCripto(res.montoCripto);
        setMonedaCripto(res.monedaCripto);
        refTiempoRestante.current = res.time;
      })
      .catch((err) => console.log(err));
  };

  const enviarIdTransaccion = (dato) => {
    Api(
      "pasarelas",
      props.props2.widget.listaIntegraciones,
      { type: "getInfoTransaccion" },
      { txnid: dato, idListado: refDatosEnviarPasarela.current.idListado },
      refRuta.current
    )
      .then((res) => {
        refEstado.current = res.estado;
        setEstado(refEstado.current);
        refBanderaCuentaRegresiva.current = "bandera";
        setBanderaCuentaRegresiva(refBanderaCuentaRegresiva.current);
      })
      .catch((err) => console.log(err));

    timeCu.current = setTimeout(() => {
      if (
        !(
          refEstado.current == "Pago completo" ||
          refEstado.current == "Cancelled / Timed Out"
        )
      ) {
        enviarIdTransaccion(dato);
      }
    }, 5000);
  };

  function copy(texto) {
    window.navigator.clipboard.writeText(texto);
    message.success("Dirección copiada al portapapeles");
  }

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
    setMontoCripto(0);
    setMonedaCripto("");
    setDireccionQr("");
    setEstado("");
    setBanderaCuentaRegresiva("");
    clearTimeout(timeCu.current);
  };

  React.useEffect(() => {
    if (!(props.props2.widget.montoFijoRango == "montoFijoTrue")) {
      if (props.props2.widget.montoMinimo) {
        refMontoMinimo.current = props.props2.widget.montoMinimo;
      }

      if (props.props2.widget.montoMaximo) {
        refMontoMaximo.current = props.props2.widget.montoMaximo;
      }
    }

    if (props.props2.widget.montoFijoRango == "elegirOpcionTrue") {
      refListaOpcionesMonto.current = props.props2.widget.opcionesMonto;
      setListaOpcionesMonto(refListaOpcionesMonto.current);
    }

    SetWidget(props.props2.widget);

    return () => clearTimeout(timeCu.current);
  }, [props.props2.widget]);

  function montoFijoRangoEleccion() {
    switch (props.props2.widget.montoFijoRango) {
      case "montoFijoTrue":
        return (
          <>
            <div style={{ marginTop: "20px" }}>
              <p style={{ marginBottom: "-5px", textAlign: "center" }}>
                Monto a pagar:
              </p>
              <p
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {props.props2.widget.montoPagar || 0}{" "}
                {" " +
                  (props.props2.widget.monedaEscogidaMostrar ||
                    "divisa no ingresada")}
              </p>
            </div>
          </>
        );
      case "rangoTrue":
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p>
                Ingresa el monto a pagar en{" "}
                {props.props2.widget.monedaEscogidaMostrar &&
                  props.props2.widget.monedaEscogidaMostrar.substring(
                    props.props2.widget.monedaEscogidaMostrar.indexOf("-") + 1
                  )}
                :
              </p>
              <InputNumber
                min={refMontoMinimo.current || 0}
                max={refMontoMaximo.current}
                value={inputValorPagar}
                step={props.props2.widget.multiplo || 1}
                style={{ width: "300px" }}
                onChange={(e) => {
                  refInputValorPagar.current = e;
                  setInputValorPagar(e);
                }}
              />
            </div>
          </>
        );
      case "elegirOpcionTrue":
        return (
          <>
            <h4 style={{ textAlign: "center" }}>
              Elige la opción que deseas tomar:
            </h4>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Select
                onChange={(e) => {
                  refInputValorPagar.current = e;
                  setInputValorPagar(e);
                }}
                placeholder="Selecciona una de las opciones"
              >
                {listaOpcionesMonto?.map((monto, index) => {
                  return (
                    <>
                      <Select.Option key={index} value={monto.montoOpcion}>
                        {monto.montoOpcion}
                      </Select.Option>
                    </>
                  );
                })}
              </Select>
            </div>
          </>
        );
      case "ninguno":
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p>
                Ingresa el monto a pagar en{" "}
                {props.props2.widget.monedaEscogidaMostrar &&
                  props.props2.widget.monedaEscogidaMostrar.substring(
                    props.props2.widget.monedaEscogidaMostrar.indexOf("-") + 1
                  )}
                :
              </p>
              <InputNumber
                placeholder="Ingresa el monto"
                step={props.props2.widget.multiplo || 1}
                style={{ width: "300px" }}
                onChange={(e) => {
                  refInputValorPagar.current = e;
                  setInputValorPagar(e);
                }}
              />
            </div>
          </>
        );
      default:
        break;
    }
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Steps
            progressDot={<div>1</div>}
            current={current}
            style={{ width: "50%" }}
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div style={{ marginTop: "24px" }}>
          {current < steps.length - 1 && (
            <div>
              <div
                style={{
                  minHeight: "200px",
                  width: "100%",
                  marginTop: "16px",
                  padding: "20px",
                  backgroundColor: "#fafafa",
                  border: "1px dashed #e9e9e9",
                  borderRadius: "10px",
                }}
              >
                {montoFijoRangoEleccion()}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "20px",
                  }}
                >
                  <p>Elige la moneda en la que quieres pagar:</p>
                  <Select
                    placeholder="Selecciona una moneda"
                    onChange={(e) => {
                      refMonedaPagar.current = e;
                      setMonedaPagar(refMonedaPagar.current);
                    }}
                    style={{ width: "300px" }}
                    showSearch
                  >
                    {props.props2.widget.listaCriptos &&
                      props.props2.widget.listaCriptos.map((moneda, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={moneda.substring(0, moneda.indexOf("-"))}
                          >
                            {moneda.substring(moneda.indexOf("-") + 1)}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
              <Button
                style={{ marginTop: "20px", alignItems: "center" }}
                type="primary"
                disabled={
                  !(props.props2.widget.montoFijoRango == "montoFijoTrue")
                    ? refMonedaPagar.current == undefined ||
                      !refInputValorPagar.current > 0
                      ? true
                      : false
                    : refMonedaPagar.current == undefined
                    ? true
                    : false
                }
                onClick={() => {
                  if (props.props2.widget.multiplo) {
                    if (
                      refInputValorPagar.current %
                        props.props2.widget.multiplo ==
                      0
                    ) {
                      next();
                      enviarDatosPeticion();
                    } else {
                      message.error(
                        `La cantidad ingresada no es múltiplo de ${props.props2.widget.multiplo}`
                      );
                    }
                  } else {
                    next();
                    enviarDatosPeticion();
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          )}
          {current === steps.length - 1 && (
            <div>
              <div
                style={{
                  minHeight: "200px",
                  width: "100%",
                  marginTop: "16px",
                  backgroundColor: "#fafafa",
                  border: "1px dashed #e9e9e9",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {direccionQr ? (
                  <>
                    <div style={{ margin: "20px" }}>
                      <img width={150} src={direccionQr.codigoQR} alt="Códgo QR" />
                    </div>
                    <strong style={{ fontSize: 40 }}>
                      {montoCripto} {monedaCripto}
                    </strong>
                    <h3>Dirección de la transacción: </h3>
                    <h2>{direccionQr.address}</h2>
                    <Button onClick={() => copy(direccionQr.address)}>
                      Copiar
                    </Button>
                    <div style={{ marginTop: "20px" }}>
                      <h3 style={{ textAlign: "center" }}>
                        Estado de la transacción:
                      </h3>
                      <h2
                        style={
                          estado == "Pago completo"
                            ? {
                                fontSize: "30px",
                                color: "#23d100",
                                textAlign: "center",
                              }
                            : estado == "Pago cancelado"
                            ? {
                                fontSize: "30px",
                                color: "#ff0000",
                                textAlign: "center",
                              }
                            : {
                                fontSize: "30px",
                                color: "#005ce6",
                                textAlign: "center",
                              }
                        }
                      >
                        {!estado ? "Tansacción sin iniciar" : estado}
                      </h2>
                    </div>
                    {banderaCuentaRegresiva && (
                      <div style={{ fontSize: "40px" }}>
                        <Countdown
                          date={Date.now() + refTiempoRestante.current * 1000}
                        />
                      </div>
                    )}
                    <div style={{ maxWidth: 300, textAlign: "center" }}>
                      <span style={{ fontSize: 12 }}>
                        Luego de enviar el pago podria tomar varios minutos
                        antes de ser recibido ya que por la naturaleza de las
                        monedas digitales requiere de ciertas validaciones.
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton.Image style={{ marginTop: "20px" }} />{" "}
                    <Skeleton
                      style={{ marginTop: "20px" }}
                      active
                      title={false}
                      paragraph={{ rows: 2 }}
                    />
                  </>
                )}
              </div>

              <Button
                style={{ marginTop: "20px" }}
                type="primary"
                onClick={() => props.funcion()}
              >
                Finalizar
              </Button>

              <Button style={{ margin: "20px 8px" }} onClick={() => prev()}>
                Anterior
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CoinPaymentsModal;
