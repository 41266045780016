import { Drawer, Form, message } from "antd";

import React from "react";
import { store } from "../../../store";
import AccionesEtapa from "./accionesEtapa";
import moment from "moment";
import DrawerListados from "./drawerAcciones/drawerListados";
import DrawerAccionesPersonalizadas from "./drawerAcciones/accionesPersonalizadas/drawerAccionesPersonalizadas";

function RenderAcciones(props) {
  const [visible, setVisibleDrawer] = React.useState(false);
  const [listado, setListado] = React.useState({});

  const resetAccion = React.useRef(null);
  const [eliminar, setEliminar] = React.useState(false);
  const agregarCamposArbitrariosAccion = React.useRef(null);
  const [editando, SetEditando] = React.useState(false);
  const [accionActual, setAccionActual] = React.useState();
  const [form] = Form.useForm();

  function guardarConfiguracionItem(values) {
    try {
      agregarCamposArbitrariosAccion.current(values, cerrarModal);
      SetEditando(false);
      form.resetFields();
    } catch (error) {
      console.log(error);
      message.error("Revisar los campos..");
    }
  }

  function cerrarModal() {
    // console.log("Se supone que lo cierro..");
    setVisibleDrawer(false);
    setAccionActual(null);
  }

  function cancelar() {
    resetAccion.current();
    form.resetFields();
    setVisibleDrawer(false);
  }

  React.useEffect(() => {
    if (editando) {
      const losCamp = store.listados.find(
        (e) => e._id === listado?.idListado
      )?.campos;

      losCamp.forEach((e) => {
        if (e.tipoCampo === "fecha") {
          listado.newItem[e.NombreCampo].campo = moment(
            listado.newItem[e.NombreCampo].campo
          );
        }
      });

      form.setFieldsValue(listado.newItem);
    }
  }, [editando]);

  return (
    <>
      <Drawer
        visible={visible}
        title={listado.listado}
        destroyOnClose
        closeIcon
        width={listado === "dispararAccion" ? 1100 : 500}
      >
        {listado === "dispararAccion" ? (
          <DrawerAccionesPersonalizadas
            cancelar={cancelar}
            guardarConfiguracionItem={guardarConfiguracionItem}
            disparador={props.objetoDisparador}
            accionActual={accionActual}
          />
        ) : (
          <DrawerListados
            eliminar={eliminar}
            guardarConfiguracionItem={guardarConfiguracionItem}
            listado={listado}
            props={props}
            editando={editando}
            form={form}
            cancelar={cancelar}
          />
        )}
      </Drawer>

      {props.objetoAcciones.map((accion) => {
        return (
          <AccionesEtapa
            setVisibleDrawer={setVisibleDrawer}
            setEliminar={setEliminar}
            setListado={setListado}
            SetEditando={SetEditando}
            disparador={props.disparador}
            objetoDisparador={props.objetoDisparador}
            EliminarAccion={props.EliminarAccion}
            accion={accion.accion}
            objetoAcciones={props.objetoAcciones}
            setObjetoAcciones={props.setObjetoAcciones}
            elid={accion.id}
            key={accion.id}
            resetAccion={resetAccion}
            agregarCamposArbitrariosAccion={agregarCamposArbitrariosAccion}
            setAccionActual={setAccionActual}
          />
        );
      })}
    </>
  );
}

export default RenderAcciones;
